import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  HostListener
} from "@angular/core";
import * as $ from "jquery";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";
// import { strictEqual } from "assert";
import { Injectable } from "@angular/core";
import { GoogleAnalyticsService } from "../services/google-analytics-service.service";
import { MedicinService } from "../services/index";
import { PrerequisiteService } from "../services/prerequisite.service";
import { CommonService } from "../services/common.service";
import { ApiService } from "../services/api.service";
import { SaveService } from "../services/save.service";
// import { parse } from "querystring";
import { GeoLocationData } from "../services/geo-location-data";



@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.css"],
  providers: [MedicinService, PrerequisiteService, CommonService, ApiService]
})
export class SearchComponent {
  public SearchItemArray: any = [];
  PopularDrugs: any = [];
  SerachList: any = [];
  SearchList: any = [];
  public ShowDisplay: boolean = false;
  public ShowDrugdetails: boolean = false;
  private SearchText: any;
  public SelectedDrug: string = "";
  public DisplayBindList: boolean = false;
  hideme = [];
  public errorShowDisplay: boolean = false;
  public daffiliate: boolean = true;
  //--------Click on drug ------------
  public NoDrugsFound: boolean = false;
  public searchtextbox_error: string = "";
  DrugDetails: any;
  coordinates: any = [];
  DrugDoseList: any = [];
  public DrugName: string = "";
  public DrugDose: string = "";
  public Latitude: string = "";
  public Longitude: string = "";

  public DrugNameTypeSingle: boolean = false;
  public DrugFormSingle: boolean = false;
  public DrugDoseSingle: boolean = false;
  public DrugCat: string = "";
  _NameFilterForQuantity: any = [];

  public Enter_Zip: boolean = false;
  public Quantity_error: string = "";
  public Quantity_Manual: string = "";
  public ManualQuantity_box: boolean = false;
  selectedValue: string;
  optionlist: any[] = [];
  DoseDescriptor_JSON: any;
  values: any;
  DrugTypeList: any = [];
  DrugQuantityList: any = [];
  LocationPermission: any = [];
  FormDoseData: any = [];
  ResponseData: any = [];
  PresetDrugData: any = [];
  public DrugConfigList: any = [];
  DrugDoseListCount: any;
  BrandType: boolean = false;
  GenericType: boolean = false;
  IsPresetFound: boolean = true;
  public ShowBrandDetails: boolean = false;
  public ShowGenericDetails: boolean = false;
  public DoseFormSingle: any = false;
  public StrengthSingle: any = false;
  public ShowDetails: boolean = false;
  public DrugQuant: any;
  public ndccode: any;
  public DrugType: string = "";
  public _IsBack: string = "No";
  public unregisterBackButtonAction: any;
  public Swap_Drug_With_MultiSource: string = "";
  public _UserZip: any = "";
  public _CurrLocation: string = "";
  public ZipCodeMessage: string = "";
  public IsAutoLocation: string = "N/A";
  DrugDetailsResponseData: any = [];
  public ShowRecentSearch: boolean = false;
  public Group_Number_Box: boolean = false;
  public getPopuphide: boolean = true;

  public toggle: boolean = true;
  pharmacypage: boolean = false;
  public enableClick: boolean;
  showloader: any = false;
  public ShowNoDetails: boolean = false;
  recaptchaStr = '';

  constructor(
    public http: HttpClient,
    private ms: MedicinService,
    public rs: PrerequisiteService,
    private route: ActivatedRoute,
    public router: Router,
    private sidebarService: CommonService,
    private googleAnalyticsService: GoogleAnalyticsService,
    public SaveService: SaveService,
    public ApiService: ApiService
  ) {
    this.router.events.subscribe(val => {
      if (window.location.href.indexOf("/Pharmacy") > -1) {
        this.pharmacypage = true;
      } else {
        this.pharmacypage = false;
      }
    });
  }
  ngOnInit() {
    this.getPosition1().subscribe((pos) => {
      this.coordinates = {
        latitude: +pos.coords.latitude,
        longitude: +pos.coords.longitude
      };
      window.localStorage.setItem("_LocalLat", this.coordinates.latitude);
      window.localStorage.setItem("_LocalLang", this.coordinates.longitude);
    });
    $(window).on("click", () => {
      this.ShowDisplay = false;
      this.DisplayBindList = false;
      $(".menu_main").removeClass("st-effect-10");
      this.hideme = [];
    });
    // ie fix
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");
    var edge = ua.indexOf("Edge/");
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return version number
      $(".headermenu").css({ "padding-top": "11px" });
    }
    if (edge > 0) {
    }
    this.showrecent_popular();
  }

  TypeDrug(stext: any) {
    this.SearchText = stext.target.value;
    if (
      this.SearchText == undefined ||
      this.SearchText == "" ||
      this.SearchText == null ||
      this.SearchText.length < 3
    ) {
      this.DisplayBindList = false;
      this.ShowDisplay = false;
      this.NoDrugsFound = false;
      this.SearchList = [];
    } else {
      this.SearchDrug(this.SearchText);
    }
  }
  SearchDrug(stext: string) {
    if (stext.length >= 3) {
      this.GetDrugList(stext);
    } else {
      this.DisplayBindList = false;
      this.ShowDisplay = false;
      this.NoDrugsFound = false;
      this.SearchList = [];
    }
  }
  CloseDrugDetails() {
    this.ShowDrugdetails = false;
    //this.ms.toggle = true;
    $("body").removeAttr("style");
  }
  CloseNoDetails() {
    this.ShowNoDetails = false;
    $("body").removeAttr("style");
  }
  GetDrugList(TypedText) {
    this.ms
      .SearchDrugList(TypedText)
      // .pipe(map(res => res.json()))
      .subscribe(
        data => {
          if (data.length > 0) {
            this.NoDrugsFound = false;
            this.SearchList = data;
          } else {
            setTimeout(() => {
              $(".heading").hide();
            }, 50);
            this.NoDrugsFound = true;
            this.SearchList = [];
          }
          if (this.SearchList.length > 0) {
            this.DisplayBindList = true;
            this.ShowDisplay = false;
            $(".heading").show();
          } else {
            this.DisplayBindList = false;
            this.ShowDisplay = false;
          }
          if (this.NoDrugsFound == true) {
            this.DisplayBindList = false;
            this.ShowDisplay = true;
            this.SearchItemArray = [];
            this.PopularDrugs = [];
            this.ShowRecentSearch = true;
            try {
              if (this.SearchList.length != 0) {
                this.ShowRecentSearch = true;
              }
            } catch { }
          }
        },
        error => { }
      );
  }
  showrecent_popular() {
    // this.getLoginState();
    try {
      let iy = 0;
      if (
        window.localStorage.getItem("SearchItemList") != null &&
        window.localStorage.getItem("SearchItemList").length != 0 &&
        window.localStorage.getItem("SearchItemList") != undefined
      ) {
        let storageitem = JSON.parse(
          window.localStorage.getItem("SearchItemList")
        );
        for (let ix = storageitem.length - 1; ix >= 0; ix--) {
          if (this.SearchItemArray.length >= 5) {
            break;
          } else {
            if (
              storageitem[ix]["RecentSearchName"] != "" &&
              storageitem[ix]["RecentSearchName"] != "" &&
              storageitem[ix]["RecentSearchName"] != undefined
            ) {
              this.SearchItemArray.push({
                value: storageitem[ix]["RecentSearchName"],
                text: storageitem[ix]["RecentSearchName"]
              });
            }
          }
        }
        this.SerachList = [];
        // try{
        //   if(this.SearchList.length != 0){
        //     this.ShowRecentSearch=true;
        //   }
        // }
        // catch{}
        for (let x3 = this.SearchItemArray.length - 1; x3 >= 0; x3--) {
          let TempItem = {
            RecentSearchName: this.SearchItemArray[x3].value
          };
          this.SerachList.push(TempItem);
        }
        window.localStorage.setItem(
          "SearchItemList",
          JSON.stringify(this.SerachList)
        );
      }
      if (this.SearchItemArray.length > 0) {
        this.ShowRecentSearch = true;
      } else {
        this.ShowRecentSearch = false;
      }
    } catch { }
    try {
      // if (
      //   window.localStorage.getItem("PopularDrugList") == "" ||
      //   window.localStorage.getItem("PopularDrugList") == null ||
      //   window.localStorage.getItem("PopularDrugList") == undefined
      // ) {
        this.ms
          .GetPopularDrug()
          // .pipe(map(res => res.json()))
          .subscribe(
            data => {
              window.localStorage.setItem(
                "PopularDrugList",
                JSON.stringify(data)
              );
              this.PopularDrugs = data;
            },
            error => { }
          );
      // } else {
      //   this.PopularDrugs = JSON.parse(
      //     window.localStorage.getItem("PopularDrugList")
      //   );
      // }
    } catch { }
  }
  ShowHistory() {
    if (this.SearchItemArray.length > 0) {
      this.ShowDisplay = true;
    }
    if (this.SelectedDrug.length > 2) {
      this.ShowDisplay = false;
    }
    this.NoDrugsFound = false;
    this.searchtextbox_error = "";
    this.errorShowDisplay = false;
    $(".searchbox_box").parent().removeClass("error_msg");
    let xarray: any = [];
    this.SearchItemArray = [];
    try {
      let iy = 0;
      if (
        window.localStorage.getItem("SearchItemList") != null &&
        window.localStorage.getItem("SearchItemList").length != 0 &&
        window.localStorage.getItem("SearchItemList") != undefined
      ) {
        let storageitem = JSON.parse(
          window.localStorage.getItem("SearchItemList")
        );
        for (let ix = storageitem.length - 1; ix >= 0; ix--) {
          if (this.SearchItemArray.length >= 5) {
            break;
          } else {
            if (
              storageitem[ix]["RecentSearchName"] != "" &&
              storageitem[ix]["RecentSearchName"] != "" &&
              storageitem[ix]["RecentSearchName"] != undefined
            ) {
              this.SearchItemArray.push({
                value: storageitem[ix]["RecentSearchName"],
                text: storageitem[ix]["RecentSearchName"],
              });
            }
          }
        }
        this.SerachList = [];
        // try{
        //   if(this.SearchList.length != 0){
        //     this.ShowRecentSearch=true;
        //   }
        // }
        // catch{}
        for (let x3 = this.SearchItemArray.length - 1; x3 >= 0; x3--) {
          let TempItem = {
            RecentSearchName: this.SearchItemArray[x3].value,
          };
          this.SerachList.push(TempItem);
        }
        window.localStorage.setItem(
          "SearchItemList",
          JSON.stringify(this.SerachList)
        );
      }
      if (this.SearchItemArray.length > 0) {
        this.ShowRecentSearch = true;
      } else {
        this.ShowRecentSearch = false;
      }
    } catch { }
    try {
      if (
        window.localStorage.getItem("PopularDrugList") == "" ||
        window.localStorage.getItem("PopularDrugList") == null ||
        window.localStorage.getItem("PopularDrugList") == undefined
      ) {
        this.ms
          .GetPopularDrug()
          // .pipe(map((res) => res.json()))
          .subscribe(
            (data) => {
              window.localStorage.setItem(
                "PopularDrugList",
                JSON.stringify(data)
              );
              //;
              this.PopularDrugs = data;
            },
            (error) => { }
          );
      } else {
        //;
        this.PopularDrugs = JSON.parse(
          window.localStorage.getItem("PopularDrugList")
        );
      }
    } catch { }

    $("#PS").show();
    // this.ShowDisplay = true;
    if ($(window).width() <= 768) {
      $(".searchbox_home").addClass("mobile_searchbox_home");
    } else {
      $(".searchbox_home").removeClass("mobile_searchbox_home");
    }

    // ie fix
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");
    var edge = ua.indexOf("Edge/");
    if (
      msie > 0 ||
      !!navigator.userAgent.match(/Trident.*rv\:11\./) ||
      edge > 0
    ) {
      // If Internet Explorer, return version number
      setTimeout(() => {
        var contentsectionHeight = $(".ie_fixed_add_n").height();
        var checkscrollbar = $(document).height() - 315 > $(window).height();
        if (checkscrollbar == true) {
          $(".searchbox_home_parent").css({
            "margin-top": `-${contentsectionHeight}px`,
            transition: "all 0.2s ease",
          });
        }
      }, 1000);
    } else {
      setTimeout(() => {
        var contentsectionHeight = $(".ie_fixed_add_n").height();
        var checkscrollbar = $(document).height() > $(window).height();
        if (checkscrollbar == true) {
          $(".searchbox_home_parent").css({
            "margin-top": `-${contentsectionHeight}px`,
            transition: "all 0.2s ease",
          });
        }
      }, 500);
    }
  }
  // toggleme(){
  //   this.ms.toggle = false;
  //   }
  clearHeight() {
    setTimeout(() => {
      $(".searchbox_home_parent").css({
        "margin-top": "0",
        transition: "all 0.2s ease"
      });
    }, 600);
  }
  removileview() {
    $(".searchbox_home").removeClass("mobile_searchbox_home");
  }

  checkLoginState() {
    var user_email = window.localStorage.getItem("UserEmail");
    var group_number = window.localStorage.getItem("UserGroup");
    var first_name = window.localStorage.getItem("FirstName");
    var last_name = window.localStorage.getItem("LastName");

    if (!!user_email && !!first_name && !!last_name && !!group_number) {
      return true
    } else {
      return false
    }
  }
  DrugSetting(_Drugname: any) {
    // if (this.checkLoginState()) {
    this.showloader = true;
    this._UserZip = $('.price_searcharea .zipbox input[type="tel"]').val();
    this.googleAnalyticsService.eventEmitter(
      "Home Page",
      "Click Drug",
      _Drugname,
      0
    );
    this.searchtextbox_error = "";
    this.ZipCodeMessage = null;
    $(".searchbox_box")
      .parent()
      .removeClass("error_msg");
    this.SelectedDrug = _Drugname;
    this.removileview();
    try {
      if (this.rs.GetUserLoggedInStatus() == "signupscreen") {
        this.ShowDisplay = false;
        this.ShowDrugdetails = false;
        $(".login_btn a")[0].click();

        return false;
      } else if (this.rs.GetUserLoggedInStatus() == "groupscreen") {
        this.ShowDisplay = false;
        this.ShowDrugdetails = false;
        this.Group_Number_Box = true;
        return false;
      }

      if (
        window.localStorage.getItem("SearchItemList") != null &&
        window.localStorage.getItem("SearchItemList").length != 0 &&
        window.localStorage.getItem("SearchItemList") != undefined
      ) {
        let RecentSearchListData = JSON.parse(
          window.localStorage.getItem("SearchItemList")
        );
        let _RecentNameFilter = RecentSearchListData.filter(item => {
          return (
            item["RecentSearchName"].toLowerCase() == _Drugname.toLowerCase()
          );
        });
        if (_RecentNameFilter.length == 0) {
          this.SerachList = [];
          window.localStorage.setItem("SearchItemList", "");

          for (let x3 = 0; x3 < RecentSearchListData.length; x3++) {
            let TempItem = {
              RecentSearchName: RecentSearchListData[x3]["RecentSearchName"]
            };
            this.SerachList.push(TempItem);
          }
          let TempItem1 = {
            RecentSearchName: _Drugname
          };
          this.SerachList.push(TempItem1);
          window.localStorage.setItem(
            "SearchItemList",
            JSON.stringify(this.SerachList)
          );
        } else {
          this.SerachList = RecentSearchListData.filter(
            uname => uname["RecentSearchName"] !== _Drugname
          );
          let TempItem1 = {
            RecentSearchName: _Drugname
          };
          this.SerachList.push(TempItem1);
          window.localStorage.setItem(
            "SearchItemList",
            JSON.stringify(this.SerachList)
          );
        }
      } else {
        let newItem = {
          RecentSearchName: _Drugname
        };
        this.SerachList = [];
        this.SerachList.push(newItem);
        window.localStorage.setItem(
          "SearchItemList",
          JSON.stringify(this.SerachList)
        );
      }
    } catch { }

    window.localStorage.setItem("SelectedDrugName", _Drugname);
    this.SelectedDrug = _Drugname;
    this.DrugComposition();
    this.ShowDisplay = false;
    this.ShowRecentSearch = false;
    this.DisplayBindList = false;
    // }
  }
  DrugComposition() {
    try {
      this.ms
        .GetDrugDetails(this.SelectedDrug)
        // .pipe(map(res => res.json()))
        .subscribe(
          data => {
            this.showloader = false;
            if(data.length == 0){
              this.ShowNoDetails = true;
            }
            if (
              data[0]["brand"].length == 0 &&
              data[0]["generic"].length == 0
            ) {
              $(".searchbox_box")
                .parent()
                .removeClass("error_msg")
                .addClass("error_msg");
              this.errorShowDisplay = true;
              this.searchtextbox_error = "Invalid drug name";
              return false;
            }
            window.localStorage.setItem(
              "SelectedDrugConfig",
              JSON.stringify(data)
            );
            this.DoseDescriptor_JSON = JSON.parse(
              this.rs.DrugDoseDescription()
            );
            this.DrugDoseList = [];
            this.optionlist = [];
            this.DrugTypeList = [];
            if (data.length > 0) {
              this.DrugDetailsResponseData = data;
              this.DrugDetails = data;
              this.ResponseData = data;
              this.ShowDetails = true;
              this.ShowDrugdetails = true;


              //this.ms.toggle = false;
              this.Enter_Zip = false;
              this.DrugType = data[0].dose;
              this.DrugDose = data[0].strength;
              this.DrugQuant = data[0].DisplayQuantity;
              this.ndccode = data[0].ndcode;
              window.localStorage.setItem("ndccode", this.ndccode);
              window.localStorage.setItem("DrugQuant", data[0].Quantity);
              window.localStorage.setItem("displayquantity", data[0].DisplayQuantity);
              $("body").css("overflow", "hidden");
              // this.DrugName = window.localStorage.getItem("SelectedDrugName");
              this.DrugName = data[0].drugname;
              this.SelectedDrug = data[0].drugname;
              window.localStorage.setItem("SelectedDrugName", this.DrugName);
              if (data[0]["brand_name_code"] == "Brand") {
                // this.MsourceList.push({ value:"BRAND", text: "Branded" });
                this.DrugCat = "Brand";
                this.BrandType = true;
                window.localStorage.setItem("IsBG", "BRAND");
                try {
                  this.DrugDoseList = [];
                  this.optionlist = [];
                  if (this.ResponseData.length > 0) {
                    let _NameFilter = this.ResponseData[0]["brand"].filter(
                      item => {
                        return (
                          item["sub_drug_name"].toLowerCase() ==
                          this.DrugName.toLowerCase()
                        );
                      }
                    );
                    for (let x of _NameFilter[0]["types"][0]["strength"]) {
                      this.DrugDoseList.push(x["Strength"]);
                    }

                    for (let x of _NameFilter[0]["types"]) {
                      try {
                        let _DoseFilter = this.DoseDescriptor_JSON.filter(
                          item => {
                            return (
                              item["Dose"].toLowerCase() == x.dose.toLowerCase()
                            );
                          }
                        );
                        if (_DoseFilter.length > 0) {
                          this.optionlist.push({
                            value: _DoseFilter[0]["Descrption"],
                            text: _DoseFilter[0]["Descrption"]
                          });
                        } else {
                          this.optionlist.push({ value: x.dose, text: x.dose });
                        }
                      } catch {
                        this.optionlist.push({ value: x.dose, text: x.dose });
                      }
                    }
                  }

                  // if (this.optionlist.length > 0) {
                  //   this.DrugType = this.optionlist[0].value; /////DosageForm(TABS/CAPS/SOLN ....)
                  // }
                  // if (this.DrugDoseList.length > 0) {
                  //   this.DrugDose = this.DrugDoseList[0].replace(" ", ""); //////// Strength & Strength Unit(10MG, 100MG/2ML .....)
                  // }
                  // setTimeout(() => {
                  //   $('.formm .active').click()
                  // }, 600);

                  this.selectedValue = this.DrugType;
                  window.localStorage.setItem("DrugType", this.DrugType);
                } catch { }
              } else {
                this.DrugCat = "Generic";
                this.BrandType = false;
                window.localStorage.setItem("IsBG", data[0]["brand_name_code"]);
                try {
                  this.DrugDoseList = [];
                  this.optionlist = [];
                  if (this.ResponseData.length > 0) {

                    let _NameFilter = this.ResponseData[0]["generic"].filter(
                      item => {

                        return (
                          item["sub_drug_name"].toLowerCase() ==
                          this.DrugName.toLowerCase()
                        );
                      }
                    );
                    for (let x of _NameFilter[0]["types"][0].strength) {

                      this.DrugDoseList.push(x["Strength"]);
                    }
                    for (let x of _NameFilter[0]["types"]) {

                      try {


                        this.optionlist.push({
                          value: x.dose,
                          text: x.dose
                        });
                      } catch {
                        this.optionlist.push({ value: x.dose, text: x.dose });
                      }
                    }
                  }
                  // if (this.optionlist.length > 0) {
                  //   this.DrugType = this.optionlist[0].value;
                  // }
                  // if (this.DrugDoseList.length > 0) {
                  //   this.DrugDose = this.DrugDoseList[0].replace(" ", "");
                  // }
                  // setTimeout(() => {
                  //   $('.formm .active').click()
                  // }, 600);

                  this.selectedValue = this.DrugType;
                  window.localStorage.setItem("DrugType", this.DrugType);
                } catch { }
                // if (this.DrugDoseList.length > 0) {
                //   this.DrugDose = this.DrugDoseList[0].replace(" ", "");
                // }
                // setTimeout(() => {
                //   $('.formm .active').click()
                // }, 600);
              }
              try {
                if (this.optionlist.length <= 1) {
                  this.DrugFormSingle = true;
                } else {
                  this.DrugFormSingle = false;
                }
              } catch { }
              try {
                if (this.DrugDoseList.length <= 1) {
                  this.DrugDoseSingle = true;
                } else {
                  this.DrugDoseSingle = false;
                }
              } catch { }
              this.InitQuantityList();
              this.DrugNameType();

              try {
                if (
                  this.DrugName == undefined ||
                  this.DrugName == "" ||
                  this.DrugQuant == undefined ||
                  this.DrugQuant == "" ||
                  this.DrugDose == undefined ||
                  this.DrugDose == "" ||
                  this.DrugType == undefined ||
                  this.DrugType == ""
                ) {
                  for (let xp of this.ResponseData[0]["generic"]) {
                    if (xp["prerset"].length > 0) {
                      if (
                        xp["prerset"][0]["DrugName"].toLowerCase() ==
                        this.DrugName.toLowerCase()
                      ) {
                        this.DrugQuant = xp["prerset"][0]["Quantity"];
                        this.DrugDose = xp["prerset"][0]["Strength"].replace(
                          " ",
                          ""
                        );
                        this.DrugType = xp["prerset"][0]["DoseForm"];
                        this.DrugCat = "Generic";
                        this.IsPresetFound = true;
                        
                        window.localStorage.setItem("IsPresetAvail", "True");
                        window.localStorage.setItem(
                          "DrugQuant",
                          this.DrugQuant
                        );
                        window.localStorage.setItem("DrugType", this.DrugType);
                        window.localStorage.setItem("DrugDose", this.DrugDose);
                        break;
                      }
                    }
                  }
                }
              } catch { }
              try {
                if (this.IsPresetFound == false) {
                  window.localStorage.setItem("IsPresetAvail", "False");
                  for (let xp of this.ResponseData[0]["brand"]) {
                    if (xp["prerset"].length > 0) {
                      if (
                        xp["prerset"][0]["DrugName"].toLowerCase() ==
                        this.DrugName.toLowerCase()
                      ) {
                        
                        this.DrugQuant = xp["prerset"][0]["Quantity"];
                        this.DrugDose = xp["prerset"][0]["Strength"].replace(
                          " ",
                          ""
                        );
                        this.DrugType = xp["prerset"][0]["DoseForm"];
                        this.DrugCat = "Brand";
                        this.IsPresetFound = true;
                        window.localStorage.setItem("IsPresetAvail", "True");
                        
                        window.localStorage.setItem(
                          "DrugQuant",
                          this.DrugQuant
                        );
                        window.localStorage.setItem(
                          "DrugQuant",
                          this.DrugQuant
                        );
                        window.localStorage.setItem("DrugType", this.DrugType);
                        window.localStorage.setItem("DrugDose", this.DrugDose);
                        break;
                      }
                    }
                  }
                }
              } catch { }
              try {
                if (this.DrugDoseList.length > 0) {
                  // this.DrugDose = this.DrugDoseList[0];
                  window.localStorage.setItem("DrugDose", this.DrugDose);
                }
              } catch { }

              if (this.optionlist.length > 1) {
                this.DoseFormSingle = false;
              } else {
                this.DoseFormSingle = true;
              }

              if (this.DrugDoseList.length > 1) {
                this.StrengthSingle = false;
              } else {
                this.StrengthSingle = true;
              }
              if (
                window.localStorage.getItem("IsBG").toLocaleLowerCase() ==
                "generic"
              ) {
                this.Swap_Drug_With_MultiSource =
                  this.SelectedDrug + " (Generic Drug)";
              } else {
                this.Swap_Drug_With_MultiSource =
                  this.SelectedDrug + " (Brand Drug)";
              }
            }
          },
          error => { }
        );
    } catch { }
  }
  InitQuantityList() {
    try {
      this.DrugQuantityList = [];
      // let _DoseFilter_1 = this.DoseDescriptor_JSON.filter(item => {
      //   return item["Descrption"].toLowerCase() == this.DrugType.toLowerCase();
      // });
      let DoseForm = this.DrugType;
      let APIResponse = this.ResponseData;
      this._NameFilterForQuantity = [];
      if (this.DrugCat.toLowerCase() == "generic") {
        this._NameFilterForQuantity = APIResponse[0]["generic"].filter(item => {
          return (
            item["sub_drug_name"].toLowerCase() == this.DrugName.toLowerCase()
          );
        });
      } else {
        this._NameFilterForQuantity = APIResponse[0]["brand"].filter(item => {
          return (
            item["sub_drug_name"].toLowerCase() == this.DrugName.toLowerCase()
          );
        });
      }
      if (this._NameFilterForQuantity.length > 0) {
        let _DoseFilter = this._NameFilterForQuantity[0]["types"].filter(
          item => {
            return item["dose"].toLowerCase() == DoseForm.toLowerCase();
          }
        );
        if (_DoseFilter.length > 0) {
          let _StrengthFilter = _DoseFilter[0]["strength"].filter(item => {
            return (
              item["Strength"].toLowerCase().replace(/\s/g, "") ==
              this.DrugDose.toLowerCase().replace(/\s/g, "")
            );
          });
          if (_StrengthFilter.length > 0) {
            for (let xqx of _StrengthFilter[0]["Quantity"]) {
              if (xqx.Quantity != 0) {
                
                // this.DrugQuantityList.push(parseInt(xqx["Quantity"]));
                this.DrugQuantityList.push(xqx);
              }
            }
          }
          if (this.IsPresetFound == false) {

            if (this.DrugQuantityList.length > 0) {
              
              this.DrugQuant = this.DrugQuantityList[0].DisplayQuantity;
              window.localStorage.setItem("ndccode", this.DrugQuantityList[0].NDCCode);
              window.localStorage.setItem("DrugQuant", this.DrugQuantityList[0].Quantity);
              window.localStorage.setItem("displayquantity", this.DrugQuantityList[0].DisplayQuantity);
            }
            if (
              window.localStorage.getItem("DrugQuant") == "" ||
              window.localStorage.getItem("DrugQuant") == null ||
              window.localStorage.getItem("DrugQuant") == undefined
            ) {
              if (this.DrugQuantityList.length > 0) {
                
                this.DrugQuant = this.DrugQuantityList[0].DisplayQuantity;
                window.localStorage.setItem("ndccode", this.DrugQuantityList[0].NDCCode);
                window.localStorage.setItem("DrugQuant", this.DrugQuantityList[0].Quantity);
                window.localStorage.setItem("displayquantity", this.DrugQuantityList[0].DisplayQuantity);
              } else {
                this.DrugQuant = 10;
                window.localStorage.setItem("DrugQuant", this.DrugQuantityList[0].Quantity);
                window.localStorage.setItem("displayquantity", this.DrugQuantityList[0].DisplayQuantity);
              }
            } else {
              this.DrugQuant = window.localStorage.getItem("DrugQuant");
            }
          }
        }
      }
    } catch { }
    if (
      this.DrugQuant == undefined ||
      this.DrugQuant == "" ||
      this.DrugQuant == null ||
      this.DrugQuantityList.length == 0
    ) {
      this.DrugQuant = "10";
      window.localStorage.setItem("DrugQuant", this.DrugQuant);
    }
    if (this.DrugQuantityList.length == 0) {
      
      this.DrugQuantityList.push(parseInt(this.DrugQuant));
    }
  }
  DrugNameType() {
    try {
      this.DrugTypeList = [];
      for (let ik = 0; ik < this.ResponseData[0]["generic"].length; ik++) {
        try {
          if (
            this.ResponseData[0]["generic"][ik][
              "sub_drug_name"
            ].toLowerCase() == this.DrugName.toLowerCase()
          ) {
          }
          this.DrugTypeList.push(
            this.ResponseData[0]["generic"][ik]["sub_drug_name"] +
            " (Generic Drug)"
          );
        } catch { }
      }
      for (let jik = 0; jik < this.ResponseData[0]["brand"].length; jik++) {
        try {
          if (
            this.ResponseData[0]["brand"][jik]["sub_drug_name"].toLowerCase() ==
            this.DrugName.toLowerCase()
          ) {
          }
          this.DrugTypeList.push(
            this.ResponseData[0]["brand"][jik]["sub_drug_name"] +
            " (Brand Drug)"
          );
        } catch { }
      }
      try {
        let removeDuplicat = this.DrugTypeList;
        this.DrugTypeList = new Set(removeDuplicat);
      } catch { }
    } catch { }
    try {
      if (this.DrugTypeList.size <= 1) {
        this.DrugNameTypeSingle = true;
      } else {
        this.DrugNameTypeSingle = false;
      }
    } catch { }
  }
  //+++--------------------------------Drug Details Popup UI-------------------------+++//
  UpdateDrugParamsWithSwaping(_drugname, _sourcetype) {
    try {

      this.DrugName = _drugname;
      window.localStorage.setItem("SelectedDrugName", this.DrugName);
      if (_sourcetype.toLowerCase() == "generic") {

        let FilterItem = this.ResponseData[0]["generic"].filter(item => {

          return item["sub_drug_name"].toLowerCase() == _drugname.toLowerCase();
        });
        if (FilterItem.length > 0) {

          this.optionlist = [];
          for (let x of FilterItem[0]["types"]) {

            try {
              // let _DoseFilter = this.DoseDescriptor_JSON.filter(item => {
              //   return item["Dose"].toLowerCase() == x.dose.toLowerCase();
              // });

              //if (_DoseFilter.length > 0) {

              this.optionlist.push({
                value: x.dose,
                text: x.dose
              });
              // } else {
              //   
              //   this.optionlist.push({ value: x.dose, text: x.dose });
              // }
            } catch {
              this.optionlist.push({ value: x.dose, text: x.dose });
            }
          }
          try {
            if (this.optionlist.length <= 1) {
              this.DrugFormSingle = true;
            } else {
              this.DrugFormSingle = false;
            }
          } catch { }
          this.UpdateDoseByFormTypeSwaping(this.optionlist[0].value);
        }
      } else {
        let FilterItem = this.ResponseData[0]["brand"].filter(item => {
          return item["sub_drug_name"].toLowerCase() == _drugname.toLowerCase();
        });
        if (FilterItem.length > 0) {
          this.optionlist = [];
          for (let x of FilterItem[0]["types"]) {
            try {
              // let _DoseFilter = this.DoseDescriptor_JSON.filter(item => {
              //   return item["Dose"].toLowerCase() == x.dose.toLowerCase();
              // });
              // if (_DoseFilter.length > 0) {
              this.optionlist.push({
                value: x.dose,
                text: x.dose
              });
              // } else {
              //   this.optionlist.push({ value: x.dose, text: x.dose });
              // }
            } catch {
              this.optionlist.push({ value: x.dose, text: x.dose });
            }
          }
          this.UpdateDoseByFormTypeSwaping(this.optionlist[0].value);
        }
      }

      if (this.optionlist.length > 1) {
        this.DoseFormSingle = false;
      } else {
        this.DoseFormSingle = true;
      }
      if (this.DrugDoseList.length > 1) {
        this.StrengthSingle = false;
      } else {
        this.StrengthSingle = true;
      }
      this.DrugType = this.optionlist[0].value;
      this.DrugDose = this.DrugDoseList[0].replace(" ", "");
      window.localStorage.setItem("DrugDose", this.DrugDose);
      window.localStorage.setItem("DrugType", this.DrugType);
      this.selectedValue = this.optionlist[0].value;
      // setTimeout(() => {
      //   $('.formm .active').click()
      // }, 600);
    } catch { }
  }
  UpdateDoseByFormTypeSwaping(_doseform) {

    let tempdoseform = _doseform;
    // try {
    //   let _DoseFilter = this.DoseDescriptor_JSON.filter(item => {
    //     
    //     return item["Descrption"].toLowerCase() == _doseform.toLowerCase();
    //   });
    //   if (_DoseFilter.length > 0) {
    //     
    //     tempdoseform = _DoseFilter[0]["Dose"];
    //   } else {
    //     tempdoseform = _doseform;
    //   }
    // } catch {
    //   tempdoseform = _doseform;
    // }

    this.DrugType = _doseform;
    window.localStorage.setItem("DrugType", _doseform);
    this.DrugDoseList = [];
    this.selectedValue = _doseform;
    try {
      if (this.ResponseData.length > 0) {
        if (this.DrugCat.toLowerCase() == "generic") {
          let _NameFilter = this.ResponseData[0]["generic"].filter(item => {
            return (
              item["sub_drug_name"].toLowerCase() == this.DrugName.toLowerCase()
            );
          });
          let _it = -1;
          for (let x of _NameFilter[0]["types"]) {
            try {
              _it += 1;
              if (x.dose == tempdoseform) {
                for (let x of _NameFilter[0]["types"][_it]["strength"]) {
                  if (this.ms.IsNullOrEmptyField(x["Strength"]) == false) {
                    this.DrugDoseList.push(x["Strength"]);
                  }
                }
                break;
              }
            } catch { }
          }
        } else {
          let _NameFilter = this.ResponseData[0]["brand"].filter(item => {
            return (
              item["sub_drug_name"].toLowerCase() == this.DrugName.toLowerCase()
            );
          });
          let _it = -1;
          for (let x of _NameFilter[0]["types"]) {
            try {
              _it += 1;
              if (x.dose == tempdoseform) {
                for (let x of _NameFilter[0]["types"][_it]["strength"]) {
                  this.DrugDoseList.push(x["Strength"]);
                }
                break;
              }
            } catch { }
          }
        }
        if (this.DrugDoseList.length > 0) {
          this.DrugDose = this.DrugDoseList[0].replace(" ", "");
          // setTimeout(() => {
          //   $('.formm .active').click()
          // }, 600);
        } else {
          this.DrugDose = "";
        }

        window.localStorage.setItem("DrugDose", this.DrugDose);
        window.localStorage.setItem("DrugType", _doseform);
        this.selectedValue = _doseform;
        if (this.DrugDoseList.length <= 1) {
          this.StrengthSingle = true;
        } else {
          this.StrengthSingle = false;
        }
      }
    } catch { }
    try {
      if (this.DrugDoseList.length <= 1) {
        this.DrugDoseSingle = true;
      } else {
        this.DrugDoseSingle = false;
      }
    } catch { }
    this.UpdateDrugQuantityOnTopChange(_doseform);
  }
  UpdateDrugQuantityOnTopChange(_doseform) {

    try {
      this.DrugQuantityList = [];
      // let _DoseFilter_1 = this.DoseDescriptor_JSON.filter(item => {
      //   return item["Descrption"].toLowerCase() == this.DrugType.toLowerCase();
      // });
      let DoseForm = _doseform;
      let APIResponse = this.ResponseData;
      this._NameFilterForQuantity = [];
      if (this.DrugCat.toLowerCase() == "generic") {
        this._NameFilterForQuantity = APIResponse[0]["generic"].filter(item => {
          return (
            item["sub_drug_name"].toLowerCase() == this.DrugName.toLowerCase()
          );
        });
      } else {
        this._NameFilterForQuantity = APIResponse[0]["brand"].filter(item => {
          return (
            item["sub_drug_name"].toLowerCase() == this.DrugName.toLowerCase()
          );
        });
      }
      if (this._NameFilterForQuantity.length > 0) {
        let _DoseFilter = this._NameFilterForQuantity[0]["types"].filter(
          item => {
            return item["dose"].toLowerCase() == DoseForm.toLowerCase();
          }
        );
        if (_DoseFilter.length > 0) {
          let _StrengthFilter = _DoseFilter[0]["strength"].filter(item => {
            return (
              item["Strength"].toLowerCase().replace(/\s/g, "") ==
              this.DrugDose.toLowerCase().replace(/\s/g, "")
            );
          });
          if (_StrengthFilter.length > 0) {
            for (let xqx of _StrengthFilter[0]["Quantity"]) {
              if (xqx.Quantity != 0) {
                
                // this.DrugQuantityList.push(parseInt(xqx["Quantity"]));
                this.DrugQuantityList.push(xqx);
              }
            }
          }
          this.DrugQuantityList.sort(function (a, b) {
            return a - b;
          });
          if (this.DrugQuantityList.length > 0) {
            
            this.DrugQuant = this.DrugQuantityList[0].DisplayQuantity;
            window.localStorage.setItem("ndccode", this.DrugQuantityList[0].NDCCode);
            window.localStorage.setItem("DrugQuant", this.DrugQuantityList[0].Quantity);
            window.localStorage.setItem("displayquantity", this.DrugQuantityList[0].DisplayQuantity);
          } else {
            this.DrugQuant = 10;
          }
          //window.localStorage.setItem("DrugQuant", this.DrugQuant);
        }
      }
    } catch { }
  }
  UpdateDrugQuantityOnTopChangebydose(dosee) {
    
    let selectedmedicine = this.SelectedDrug;
    let form = dosee;
    let dose = this.DrugType;
    try {
      this.DrugQuantityList = [];
      // let _DoseFilter_1 = this.DoseDescriptor_JSON.filter(item => {
      //   return item["Descrption"].toLowerCase() == this.DrugType.toLowerCase();
      // });
      // let DoseForm = _doseform;
      let APIResponse = this.ResponseData;
      this._NameFilterForQuantity = [];
      if (window.localStorage.getItem('IsBG').toLowerCase() == "generic") {
        
        this._NameFilterForQuantity = APIResponse[0]["generic"].filter(item => {
          
          return (
            item["sub_drug_name"].toLowerCase() == selectedmedicine.toLowerCase()
          );
        });
      } else {
        this._NameFilterForQuantity = APIResponse[0]["brand"].filter(item => {
          
          return (
            item["sub_drug_name"].toLowerCase() == selectedmedicine.toLowerCase()
          );
        });
      }
      if (this._NameFilterForQuantity.length > 0) {
        let _DoseFilter = this._NameFilterForQuantity[0]["types"].filter(
          item => {
            
            return item["dose"].toLowerCase() == dose.toLowerCase();
          }
        );
        if (_DoseFilter.length > 0) {
          let _StrengthFilter = _DoseFilter[0]["strength"].filter(item => {
            
            return (
              item["Strength"].toLowerCase().replace(/\s/g, "") ==
              form.toLowerCase().replace(/\s/g, "")
            );
          });
          if (_StrengthFilter.length > 0) {
            for (let xqx of _StrengthFilter[0]["Quantity"]) {
              
              if (xqx.Quantity != 0) {
                
                // this.DrugQuantityList.push(parseInt(xqx["Quantity"]));
                this.DrugQuantityList.push(xqx);
              }
            }
          }
          this.DrugQuantityList.sort(function (a, b) {
            return a - b;
          });
          if (this.DrugQuantityList.length > 0) {
            
            this.DrugQuant = this.DrugQuantityList[0].DisplayQuantity;
            window.localStorage.setItem("ndccode", this.DrugQuantityList[0].NDCCode);
            window.localStorage.setItem("DrugQuant", this.DrugQuantityList[0].Quantity);
            window.localStorage.setItem("displayquantity", this.DrugQuantityList[0].DisplayQuantity);
          } else {
            this.DrugQuant = 10;
          }
          //window.localStorage.setItem("DrugQuant", this.DrugQuant);
        }
      }
    } catch { }
  }
  DrugQuantityValidate(e: any) {
    this.Quantity_error = "";
    $("#Quantity_Manual")
      .parent()
      .removeClass("error_msg");
    var keyCode = e.which ? e.which : e.keyCode;
    if (keyCode != 8 && keyCode != 0 && (keyCode < 48 || keyCode > 57)) {
      return false;
    }
  }
  // SubmitQuantity() {
  //   if (
  //     this.Quantity_Manual == null ||
  //     this.Quantity_Manual == "" ||
  //     this.Quantity_Manual == undefined
  //   ) {
  //     this.Quantity_error = "Please enter a quantity";
  //     $("#Quantity_Manual")
  //       .parent()
  //       .addClass("error_msg");
  //     return false;
  //   } else {
  //     this.googleAnalyticsService.eventEmitter(
  //       "Drug Quantity Pop Up",
  //       "Drug Quantity",
  //       this.DrugQuant + "<-->" + this.Quantity_Manual,
  //       0
  //     );
  //     this.ManualQuantity_box = false;
  //     this.DrugQuant = this.Quantity_Manual;
  //     window.localStorage.setItem("DrugQuant", this.DrugQuant);
  //   }
  // }
  Redirect_LoadPharmacy(captchaRef: any) {
    this.zipCodeLenth();
    let getLenth = this._UserZip.length;
    if (getLenth < 5) {
      this.ZipCodeMessage = "Please enter valid zipcode";
      $(".PharmacyNear").addClass("error_msg");
      return false;
    }
    $(".homepage, html").removeAttr("style");
    this.Enter_Zip = false;
    if (this.ms.IsNullOrEmptyField(this._UserZip) == true) {
      this.ZipCodeMessage = "Please enter valid zipcode";
      $(".PharmacyNear").addClass("error_msg");
    } else {
      this.GetLatLongByZipCode(this._UserZip);
      $("body").removeAttr("style");
    }
    if (this.recaptchaStr) {
      captchaRef.reset();
    }
    captchaRef.execute()
  }
  ClearErrorMessage() {
    this.ZipCodeMessage = "";
    $(".PharmacyNear").removeClass("error_msg");
  }
  public resolved(captchaResponse: string): void {
    debugger;
    // ;
    this.recaptchaStr = captchaResponse;
    if (this.recaptchaStr) {
      // ;
    }

  }

  GetLatLongByZipCode(ZipCode: string) {
    let _Zip = "";
    if (String(ZipCode).length == 4) {
      _Zip = "0" + String(ZipCode);
    } else {
      _Zip = String(ZipCode);
    }
    this.ms
      .GetCoordinatebyzip(_Zip)
      // .pipe(map(res => res.json()))
      .subscribe((data: GeoLocationData) => {
        try {
          if (data.results.length > 0) {
            this._CurrLocation = data.results[0]["formatted_address"];
            window.localStorage.setItem(
              "UserLat",
              data.results[0].geometry.location.lat
            );
            window.localStorage.setItem(
              "UserLong",
              data.results[0].geometry.location.lng
            );
            window.localStorage.setItem("userZipCode", _Zip);
            window.localStorage.setItem(
              "UserCurrentAddress",
              this._CurrLocation
            );
            
           // window.localStorage.setItem("DrugQuant", this.DrugQuant);
            let Pharmacy_Request_Params =
              window.localStorage.getItem("SelectedDrugName") +
              "|" +
              window.localStorage.getItem("DrugType") +
              "|" +
              window.localStorage.getItem("DrugDose") +
              "|" +
              window.localStorage.getItem("DrugQuant") +
              "|" +
              window.localStorage.getItem("IsBG") +
              "|" +
              window.localStorage.getItem("userZipCode");
            this.googleAnalyticsService.eventEmitter(
              "Drug Details Pop Up",
              "Click to Load Pharmacy",
              Pharmacy_Request_Params,
              0
            );
            if (this.pharmacypage) {
              this.ShowDrugdetails = false;
              const navigationExtras: NavigationExtras = {
                state: {
                  Page: window.location.href.replace(/(.*\/)*/, "")
                }
              };
              this.router
                .navigateByUrl("/RefreshComponent", {
                  skipLocationChange: true
                })
                .then(() => {
                  this.router.navigate(["/Pharmacy"], navigationExtras);
                });
            } else {
              const navigationExtras: NavigationExtras = {
                state: {
                  Page: "Home"
                }
              };

              this.router.navigate(["/Pharmacy"], navigationExtras);
            }
          } else {
            _Zip = String(ZipCode);
            this._UserZip = _Zip;
            this._CurrLocation = this._UserZip;
            this.ZipCodeMessage = "Please enter valid zipcode";
            $(".PharmacyNear").addClass("error_msg");
          }
        } catch { }
      });
  }
  zipCodeLenth() {
    let zipLen = $("#getzipcode").val();
    if (zipLen == "") {
      this.ZipCodeMessage = "Please enter valid zipcode";
      $(".PharmacyNear").addClass("error_msg");
    }
  }
  // ie fix issue
  setHeight(val) {
    var outHeight = $("body").outerHeight();
    var hHome = $(".homepage").outerHeight();
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");
    var edge = ua.indexOf("Edge/");
    if (val == "focusin") {
      if (hHome <= 749) {
        //$(".homepage").css({'height':`${outHeight}px`});
      }
    } else {
      $(".homepage, html, body").removeAttr("style");
    }
    if (
      msie > 0 ||
      !!navigator.userAgent.match(/Trident.*rv\:11\./) ||
      edge > 0
    ) {
      // If Internet Explorer, return version number
      $("html").css({ "overflow-y": "hidden" });
      if (val == "focusin") {
        var outHeight = $("body").outerHeight();
        var hHome = $(".homepage").outerHeight();
        if (hHome <= 749) {
          $(".homepage").css({
            height: `${outHeight}px`,
            "overflow-y": "hidden"
          });
          setTimeout(() => {
            $(".serachlist").css({
              "max-height": "205px",
              "min-height": "205px"
            });
          }, 500);
        }
      } else if (val == "focusout") {
        $("html").css({ "overflow-y": "hidden" });
      } else {
        $(".homepage, html, body, .serachlist").removeAttr("style");
      }
    }
  }
  ZipCodeValidate(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    $("#pick_location").prop("checked", false);
    return true;
  }

  zipCodeLenthInit() {
    let zipLen = new String($("#getzipcode").val()).length;
    if (zipLen < 5) {
      $("#pick_location").prop("checked", false);
    }
  }
  addActive_select(event, number, selected_value, ndc) {
    $(event.target)
      .parent()
      .find("li")
      .removeClass("active");
    event.target.classList.add("active");
    this.hideme[number] = false;
    if (number == 0) {
      this.googleAnalyticsService.eventEmitter(
        "Drug Details Pop Up",
        "Swap Drug Name",
        this.SelectedDrug + "<-->" + selected_value,
        0
      );
      this.SelectedDrug = selected_value
        .replace(" (Generic Drug)", "")
        .replace(" (Brand Drug)", "")
        .trim();
      window.localStorage.setItem("SelectedDrugName", this.SelectedDrug);
      if (selected_value.indexOf("Generic Drug") > -1) {
        this.DrugCat = "Generic";
        window.localStorage.setItem("IsBG", this.DrugCat);
      } else {
        this.DrugCat = "Brand";
        window.localStorage.setItem("IsBG", this.DrugCat);
      }
      this.UpdateDrugParamsWithSwaping(this.SelectedDrug, this.DrugCat);
    } else if (number == 1) {
      this.googleAnalyticsService.eventEmitter(
        "Drug Details Pop Up",
        "Swap Drug Form",
        this.DrugType + "<-->" + selected_value,
        0
      );
      this.DrugType = selected_value;
      window.localStorage.setItem("DrugType", this.DrugType);
      this.UpdateDoseByFormTypeSwaping(this.DrugType);
    } else if (number == 2) {
      this.googleAnalyticsService.eventEmitter(
        "Drug Details Pop Up",
        "Swap Drug Dosage",
        this.DrugDose + "<-->" + selected_value,
        0
      );
      this.DrugDose = selected_value;
      window.localStorage.setItem("DrugDose", this.DrugDose);
      this.UpdateDrugQuantityOnTopChangebydose(selected_value)
    } else if (number == 3) {
      this.googleAnalyticsService.eventEmitter(
        "Drug Details Pop Up",
        "Swap Drug Quantity",
        this.DrugQuant + "<-->" + selected_value,
        0
      );
      this.DrugQuant = selected_value.DisplayQuantity;
      window.localStorage.setItem("DrugQuant", selected_value.Quantity);
      window.localStorage.setItem("ndccode", ndc);   
      window.localStorage.setItem("displayquantity", selected_value.DisplayQuantity);   
    } else if (number == -3) {
      this.Quantity_Manual = null;
      this.ManualQuantity_box = true;
    }
  }
  GetAutoLocation(e) {
    var AutoLocate = e.target.checked;
    if (AutoLocate == true) {
      try {
        this.ZipCodeMessage = "";
        $(".PharmacyNear").removeClass("error_msg");
        this.googleAnalyticsService.eventEmitter(
          "Click for Auto Location",
          "User Current Co-Ordinate",
          window.localStorage.getItem("_LocalLat") +
          "," +
          window.localStorage.getItem("_LocalLang"),
          0
        );
        window.localStorage.setItem(
          "UserLat",
          window.localStorage.getItem("_LocalLat")
        );
        window.localStorage.setItem(
          "UserLong",
          window.localStorage.getItem("_LocalLang")
        );
        this.GetLatLongByCo_ordinate(
          window.localStorage.getItem("_LocalLat"),
          window.localStorage.getItem("_LocalLang")
        );
      } catch { }
    } else {
      this._UserZip = "";
      this.IsAutoLocation = "Not Found";
      this._CurrLocation = "";
      window.localStorage.setItem("UserLat", "");
      window.localStorage.setItem("UserLong", "");
      window.localStorage.setItem("userZipCode", "");
    }
  }
  GetLatLongByCo_ordinate(lat: string, long: string) {
    if (lat == "" || lat == undefined || lat == null) {
      return false;
    }
    this.ms
      .GetLocationByLatLongWithSensor(lat, long)
      // .pipe(map(res => res.json()))
      .subscribe(data => {
        try {
          for (let ix of data.results) {
            if (ix["types"][0] == "street_address") {
              let tempaddress = "";
              for (
                let ad = 1;
                ad < ix["formatted_address"].split(",").length;
                ad++
              ) {
                if (
                  tempaddress == "" ||
                  tempaddress == null ||
                  tempaddress == undefined
                ) {
                  tempaddress = ix["formatted_address"].split(",")[ad];
                } else {
                  tempaddress =
                    tempaddress + "," + ix["formatted_address"].split(",")[ad];
                }
              }
              this._CurrLocation = tempaddress; //ix["formatted_address"];
              window.localStorage.setItem(
                "UserCurrentAddress",
                this._CurrLocation
              );
              break;
            }
          }
          try {
            if (data.results.length > 0) {
              for (let ix of data.results[0]["address_components"]) {
                if (ix["types"] == "postal_code") {
                  window.localStorage.setItem("userZipCode", ix["long_name"]);
                  this._UserZip = ix["long_name"];
                  this.IsAutoLocation = "Found";
                  break;
                }
              }
            }
          } catch { }
        } catch { }
      });
    // if(window.localStorage.getItem("userZipCode")!=""&&window.localStorage.getItem("userZipCode")!=null&&window.localStorage.getItem("userZipCode")!=undefined)
    // {
    //   this.router.navigate(['/Pharmacy']);
    // }
  }
  public getPosition1()   {
    return Observable.create(observer => {
      navigator.geolocation.watchPosition((pos) => {
        observer.next(pos);
      }),
        () => {
          console.log("Position is not available");
        },
      {
        enableHighAccuracy: true
      };
    });
  }
  removeforgot(event) {
    this.ManualQuantity_box = false;
  }
  ClickDrug() {
    this.ZipCodeMessage = null;
    if (this.rs.GetUserLoggedInStatus() == "signupscreen") {
      this.ShowDisplay = false;
      this.ShowDrugdetails = false;
      $("body").removeAttr("style");
      $(".login_btn a")[0].click();
      return false;
    } else if (this.rs.GetUserLoggedInStatus() == "groupscreen") {
      this.ShowDisplay = false;
      this.ShowDrugdetails = false;
      this.Group_Number_Box = true;
      return false;
    } else {
      if (this.ms.IsNullOrEmptyField(this.SelectedDrug) == true) {
        this.errorShowDisplay = true;
        this.searchtextbox_error = "Type or search any drug";
        $(".searchbox_box")
          .parent()
          .removeClass("error_msg")
          .addClass("error_msg");
      } else {
        this.errorShowDisplay = false;
        this.searchtextbox_error = "";
        $(".searchbox_box")
          .parent()
          .removeClass("error_msg");
        this.googleAnalyticsService.eventEmitter(
          "Home Page",
          "Click on Find Lowet Pharmacy Button",
          this.SelectedDrug,
          0
        );
        this.DrugSetting(this.SelectedDrug);
      }
    }
  }

  getCardHide() {
    // this.daffiliate = false;
    $("#daffiliate").hide();
  }
  getCardShow() {
    // this.daffiliate = false;
    $("#daffiliate").show();
    $("html").removeAttr("style");
  }
}
