<!-- Search medicine section -->
<section class="search_medicine new-design" id="search-medicine">
  <div class="comman-width">
    <h2>Search below to <span> save up to $95</span> on your prescriptions!</h2>
  <app-search></app-search>
    <div class="btn-section">
      <a href="#form-section" class="get">Get the GlicRx card!</a>
      <a href="#howItWorks" class="get">How GlicRx works?</a>  
    </div>
  </div>
</section>

<!-- // welcome section \\ -->
<section id="form-section" class="welcome-section homePageSecOne home-new-design">
  <div class="comman-width">
    <div class="welcome-center">
      <h2>
        Get your free discount card and save up to $95 on your prescription.
      </h2>
      <div class="mobile_mobile_image">
        <img loading="lazy" src="../assets/images/mobile_phone_mobile.webp" alt="" />
      </div>

      <!-- <app-search></app-search> -->
      <!-- search start -->
      <!-- Form Field -->
      <div class="formsec">
        <div class="form-group">
          <input type="text" class="form-control" [(ngModel)]="first_name" value="Shane" id="first_name"
                 placeholder="First Name" autocomplete="off" (keyup)="removeerror($event)" />
          <span style="display: none;" id="fnameerror">
            {{
            errormessageFName
            }}
          </span>
        </div>
        <div class="form-group">
          <input type="text" class="form-control" [(ngModel)]="last_name" value="Mike" id="last_name"
                 placeholder="Last Name" autocomplete="off" (keyup)="removeerror($event)" />
          <span style="display: none;" id="lnameerror">
            {{
            errormessageLName
            }}
          </span>
        </div>
        <div class="form-group">
          <input type="email" class="form-control" value="shane.mike@gmail.com" [(ngModel)]="email" id="email"
                 placeholder="Email" autocomplete="off" (keyup)="removeerror($event)" />
          <span style="display: none;" id="emailerror">
            {{
            errormessageEmail
            }}
          </span>
        </div>
        <div class="form-group" *ngIf="group_number">
          <input type="text" class="form-control" [(ngModel)]="access_code" value="" id="access_code"
                 placeholder="Access Code" autocomplete="off" (keyup)="removeerror($event)" />
          <span style="display: none;" id="incorrect_groupnumber">
            {{
            incorrect_groupnumber
            }}
          </span>
          <div class="reset_text" (click)="setDefaultGroup()" *ngIf="group_number">
            {{"Don't have an access code?"}}
          </div>
      </div>
        <div class="btn_desc">
          <a href="javascript:;" class="btnshover" (click)="setGroupNumber($event)">Start Saving Today!</a>
        </div>
        <div class="x-section">
          <p>
            <strong>GlicRx is <span style="text-decoration: underline;">not</span> insurance.</strong> By clicking "Start Saving Today!", you are agreeing to our terms and conditions and privacy policy, which can be found <a href="https://glicrx.com/terms-and-conditions">here</a>.
          </p>
        </div>
        <div class="successhome_msg" *ngIf="cardsuccess">
          <img src="../assets/images/successicon.svg" alt="" />
          Check your email to start saving today!
        </div>
      </div>
      <div class="search-fields">
        <!-- <app-search></app-search> -->
      </div>
      <!-- search end -->
      <!--
      <div class="search">
        <p>How it works:</p>
        <ul>
          <li>
            <div>
              <img src="../assets/images/hero_sec_location.svg" />
            </div>
            <span>Search for prescriptions at pharmacies near you.</span>
          </li>
          <li>
            <div>
              <img src="../assets/images/hero_discount.svg" />
            </div>
            <span>Use discount card to save up to 80%* off.</span>
          </li>
          <li>
            <div>
              <img src="../assets/images/hero_card.svg" />
            </div>
            <span>Show pharmacist your card at purchase.</span>
          </li>
        </ul>
      </div>
    -->
    
    </div>
  </div>
</section>
<!-- \\ welcome section // -->

<div class="clearfix"></div>

<!-- // easy section \\ -->
<section id="howItWorks" class="easy-section new-design">
  <div class="easy-content">
    <h2>How does GlicRx work?</h2>
    <span class="easy-step">3 Easy Steps!</span>
    <div class="row">
      <div class="col-lg-4 col-sm-4 col-xs-12">
        <a class="step1-click" (click)="pharmacynavigate()" routerLinkActive="active">
          <img loading="lazy" width="39" height="59" src="../assets/images/how-it-works-new-icon1.png" class="onweb" alt="" />
          <span>Step 1</span>
          <p>Search for prescription and find the lowest price at a pharmacy near you.</p>
        </a>
      </div>
      <div class="col-lg-4 col-sm-4 col-xs-12">
        <img loading="lazy" width="53" height="59" src="../assets/images/how-it-works-new-icon2.png" class="onweb" alt="" />
        <span>Step 2</span>
        <p>Get your free discount card by downloading the mobile app or by entering your information here.</p>
      </div>
      <div class="col-lg-4 col-sm-4 col-xs-12">
        <img loading="lazy" width="55" height="59" src="../assets/images/how-it-works-new-icon3.png" class="onweb" alt="" />
        <span>Step 3</span>
        <p>Present your card to the pharmacist and save up to $95 off your medication.</p>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="get-card-buttons">
      <a href="#form-section" class="card">Get Card Now!</a>    
      <a [routerLink]="['/how-it-works']" (click)="slideToggle()" routerLinkActive="active" class="card gap">Learn More</a>  
    </div>
  </div>
</section>
<!-- \\ easy section // -->

<div class="clearfix"></div>

<!-- // welcome section \\ -->
<section class="accept-section homePageSecTwo">
  <!-- <h2>Accepted at over 35,000 pharmacies nationwide</h2> -->
  <!-- <ul>
    <li *ngFor="let Item of acceptLogoArray">
      <img src="{{ Item.img }}" alt="{{ Item.alt }}" />
    </li>
  </ul>

  <a
    href="javascript:;"
    data-toggle="modal"
    data-target="#myModal"
    class="card btn-hover-effect"
    ><div class="insider"></div>
    Get Your Card Now</a
  > -->
  <div class="acceptedsec">
    <h3 class="onmobile">
      Accepted at over
    </h3>
    <h2 class="onmobile">35,000</h2>
    <p class="onmobile">pharmacies nationwide</p>
    <img loading="lazy" width="358" src="../assets/images/accepted_mobile.webp" class="onmobile" alt="" />
    <img loading="lazy" width="478" height="358" src="../assets/images/accepted.webp" class="onweb" alt="" />
    <div class="howitwork">
      <h2>How do we do it?</h2>
      <p>
        On behalf of consumers nationwide, GlicRx has negotiated a
        discount rate with a national network of drugstores and retail outlets.
        <span>Over 35,000 stores have partnered with GlicRx</span>
        to offer prescription assistance to the uninsured and under-insured.
      </p>
      <h2 class="heading-gap">Who Uses Our Card?</h2>
      <p>Over 60% of GlicRx users have insurance or a health savings plan. If you are un-insured, have a high deductible or high copay; we can help!</p>
      <a [routerLink]="['/how-it-works']" routerLinkActive="active" class="card btn-hover-effect btnshover">
        Learn More
      </a>
    </div>
  </div>
</section>
<!-- \\ welcome section // -->
<!-- // carosuel section \\ -->
<section class="carosuel-section homePageSecFour testimonial">
  <div class="comman-width">
    <div class="testimonial_inner">
      <h3>Testimonials</h3>
      <h2>What customers are saying.</h2>
      <div class="row">
        <div class="col-lg-12">
          <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem *ngFor="let slide of slides" class="slide">
              <div class="shadow">
                <!-- <img src="{{ slide.img }}" alt="pharmacist" /> -->
                <h3>{{ slide.heading }}</h3>
                <p>{{ slide.text }}</p>
              </div>
            </div>
          </ngx-slick-carousel>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- \\ carosuel section // -->

<div class="clearfix"></div>

<!-- // download section \\ -->
<!-- <section class="download-section homeDownload">
  <div class="comman-width">
    <div class="row">
      <div class="col-lg-12">
        <span>Download our FREE top rated App</span>
        <h2>Get {{ secondBrandName }} on the Go</h2>
        <div class="app-link">
          <a
            href="https://apps.apple.com/us/app/honest-rx/id1439777162"
            target="_blank"
            ><img class="gap" src="assets/images/ios.svg"
          /></a>
          <a
            href="https://play.google.com/store/apps/details?id=com.firebase.honest"
            target="_blank"
            ><img src="assets/images/android.svg"
          /></a>
        </div>

        <span class="get">or get the download link</span>

        <form class="{{ sendmessageClass }}">
          <input
            type="text"
            name="phone-number"
            class="form-control"
            [(ngModel)]="sendno"
            placeholder="Enter your phone number"
            (keypress)="removemsgerr($event)"
            (focus)="removemsgerr($event)"
            maxlength="14"
            autocomplete="off"
          />
          <button
            (click)="sendmsg(sendno)"
            type="submit"
            class="btn btn-hover-effect"
          >
            <div class="insider"></div>
            Get Link
          </button>
          <span>{{ sendmessageStatus }}</span>
        </form>
      </div>
    </div>
  </div>
</section> -->
<!-- \\ download section // -->
<!-- <app-getcard></app-getcard> -->
