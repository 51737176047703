import { Component, OnInit } from "@angular/core";
import * as $ from "jquery";
import { Router } from "@angular/router";

import { MedicinService } from "../services/index";
import { PrerequisiteService } from "../services/prerequisite.service";
import { CommonService } from "../services/common.service";
import { map } from "rxjs/operators";
import { } from "googlemaps";
// import { DECLARATION_VIEW } from "@angular/core/src/render3/interfaces/view";
// import { detachEmbeddedView } from "@angular/core/src/view";
import { GoogleAnalyticsService } from "../services/google-analytics-service.service";
import { ApiService } from "../services/api.service";
import domtoimage from 'dom-to-image-more';
import jspdf from 'jspdf';
// import { debug } from "console";

@Component({
  selector: "app-pharmacy",
  templateUrl: "./pharmacy.component.html",
  styleUrls: ["./pharmacy.component.scss"],
  providers: [MedicinService, PrerequisiteService, GoogleAnalyticsService, ApiService],
})
export class PharmacyComponent implements OnInit {
  public DrugNameTypeSingle: boolean = false;
  public DrugFormSingle: boolean = false;
  public DrugDoseSingle: boolean = false;
  public AppName: string = "";
  public LoggedIn: string = "";
  sortbyddl: any = false;
  type_list = [];
  public UserInputEmail: string = "";
  //----Pharmacylist variables
  public List_Pharmacy_Available: boolean = true;
  public pharmacy_list: any = [];
  public APICallCount: any = 0;
  public PharmacyList: any = [];
  public PharmacyList_CRX: any = [];
  public PharmacyList_DST: any = [];
  public PharmacyList_NVT: any = [];
  public PharmacyList_RXS: any = [];
  //------Call API Params
  public Quantity_error: string = "";
  public Quantity_Manual: string = "";
  public ManualQuantity_box: boolean = false;
  public MarkersList: any = [];
  public _SortType: string = "Distance";
  public IsPricesort = true;
  public IsDistancesort = false;
  public distancesort: any;
  public _Lat: string = "";
  public _Long: string = "";
  public _Quant: string = "";
  public _Dose: string = "";
  public _Type: string = "";
  public _DrugName: string = "";
  public _MultiSource: string = "";
  public _UserId: string = "";
  public _ZipCode: string = "";
  public _UserLocation: string = "";
  public RecentSavecard: string = "";
  public ZipCodeMessage: string = "";
  DoseDescriptor_JSON: any;
  DrugNameTypeList: any[] = [];
  DrugFormTypeList: any = [];
  DrugDoseTypeList: any = [];
  DrugQuantityTypeList: any = [];
  _NameFilterForQuantity: any = [];
  DrugDoseDescriptorList: any = [];
  LocationPermission: any = [];
  FormDoseData: any = [];
  PresetDrugData: any = [];
  public ConfigResponseData: any = [];
  //----Map Variables
  lat: number;
  lng: number;
  zoom: number = 12;
  //selectedMarker;
  //----Card variables
  public SelectPharmacy: string;
  public PharmacyName: string;
  public PharmacyPrice: string;
  public RetailPrice: string;
  public Savings_Percent: string;
  public PharmacyContact: string;
  public MemberId: string;
  public RXBin: string;
  public RXCPN: string;
  public HelpLine: string;
  public DialNumber: string;
  public GroupNumber: string;
  public NoDefaultCard: Boolean = false;
  public PAddress1: string = "";
  public PAddress2: string = "";
  public PAddress3: string = "";
  public gpid: any;
  public multisource: any;
  public SavedCardList: any = [];
  public SavedCardList_Login: any = [];
  public unregisterBackButtonAction: any;
  public Quant_Type: string = "";
  public PharmacyPhone: string = "";
  public Card_Quantity: string = "";
  public Card_DrugName: string = "";
  public Card_Dosage: string = "";
  public Card_DrugForm: string = "";
  public PharmacyLat: string = "";
  public PharmacyLng: string = "";
  public IsSave: string = "";
  public PharmacyType: string = "";
  public DrugMultiSource: string = "";
  public SelectedCardId: string = "";
  public PharmacistHelpline: string = "Pharmacist Helpline";
  public PharmacistHL: string = "";
  public Card_Pharmacy_Distance: string = "";
  public Card_CardId: string = "";
  public Card_PharmacyId: string = "";
  public Card_UID: string = "";
  public Card_Pharmacy_Logo: string = "";
  public RXS_Walmart: string = "";
  public RXS_CVS: string = "";
  public Bin: string = "";
  public Pcn: string = "";
  public Plogo: string = "";
  //--------Drug Details Swaping--------------
  public Swap_Drug_Name: string = "";
  public Swap_Drug_Form: string = "";
  public Swap_Drug_Dose: string = "";
  public Swap_Drug_MultiSource: string = "";
  public Swap_Drug_With_MultiSource = "";
  public Swap_Drug_With_MultiSource_New = "";
  public _UserZip = "";
  public _CurrLocation: string = "";
  public Enter_Zip: boolean = false;
  //--------Share Discount card
  public SendDiscountMessage: string = "";
  public Card_Type: string = "";
  public getBrowser: string = "";
  firstloadpricing = false;
  public IsAutoLocation: string = "N/A";
  public Pagename: string;
  public showshare: number;
  showloader: any = false;
  public ConditionalId: string;
  recaptchaStr: "";
  robomsg:any="";
  robopopup:any=false

  constructor(
    public ms: MedicinService,
    public router: Router,
    private rs: PrerequisiteService,
    private sidebarService: CommonService,
    private googleAnalyticsService: GoogleAnalyticsService,
    public ApiService: ApiService
  ) {
    const navigation = this.router.getCurrentNavigation();
    console.log('page+ ', navigation);
    if (navigation.extras.state != undefined) {
      const state = navigation.extras.state as {
        Page: string;
      };

      if (state.Page != "") {
        this.Pagename = state.Page;
      } else {
        this.Pagename = "Home";
      }
    } else {
      this.Pagename = "Pharmacy";
    }
    console.log(navigation.finalUrl.queryParams.Enrollmentcenter)
    const url = this.router.serializeUrl(this.router.createUrlTree(['/Pharmacy'], { queryParams: { 'Enrollmentcenter': "ec" } }));
    if (navigation.finalUrl.queryParams.Enrollmentcenter == "ec") {
      this.Pagename = "Fromheader";
      var currURL = window.location.href;
      var urll = (currURL.split(window.location.host)[1]).split("?")[0];
      window.history.pushState({}, document.title, urll);
    }
    if (this.rs.GetUserLoggedInStatus() == "signupscreen") {
      this.router.navigate(["/"]);
    } else {
      // this.AppName = window.localStorage.getItem('WhiteName');
      if (
        window.localStorage.getItem("UserLat") &&
        window.localStorage.getItem("UserLong")
      ) {
        this.AppName = "You're Here!";
        this._Lat = window.localStorage.getItem("UserLat");
        this._Long = window.localStorage.getItem("UserLong");
        this._Quant = window.localStorage.getItem("displayquantity");
        this._Dose = window.localStorage.getItem("DrugDose");
        this._Type = window.localStorage.getItem("DrugType");
        this._DrugName = window.localStorage.getItem("SelectedDrugName");
        this._MultiSource = window.localStorage.getItem("IsBG");
        this._UserId = window.localStorage.getItem("UserId");
        this._ZipCode = window.localStorage.getItem("userZipCode");
        this._UserLocation = window.localStorage.getItem("UserCurrentAddress");
        this.Card_DrugForm = this._Type;
        this.lat = parseFloat(this._Lat);
        this.lng = parseFloat(this._Long);
        this._UserZip = this._ZipCode;
        this._CurrLocation = this._UserZip;
        if (this.Pagename == "Home" || this.Pagename == "Pharmacy") {
          setTimeout(() => {
            this.LoadPharmacyList();
          }, 300);
        }
        // ;
        this.DrugConfiguration();

        if (this._MultiSource.toLocaleLowerCase() == "generic") {
          this.Swap_Drug_With_MultiSource_New =
            this._DrugName + " (Generic Drug)";
        } else {
          this.Swap_Drug_With_MultiSource_New =
            this._DrugName + " (Brand Drug)";
        }
        this.MemberId = window.localStorage.getItem("UserCharId");
        if (
          this.MemberId == null ||
          this.MemberId == undefined ||
          this.MemberId == "" ||
          this.MemberId == "null"
        ) {
          // group number condition
          let member_Id = '100-4542';
          let wholesalername = window.localStorage.getItem('WhName')
          if (wholesalername == "Avenel" && this.GroupNumber != 'GST1016') {
            member_Id = "12345"
          }
          if (this.GroupNumber === 'GST1016') {
            member_Id = '48786';
          }
          this.MemberId = member_Id;
          window.localStorage.setItem("UserCharId", member_Id);
        }
      } else {
        this.firstloadpricing = true;
        $("body").css({ opacity: "1", "pointer-events": "fill" });
        this.DrugNameTypeSingle = true;
        this.DrugFormSingle = true;
        this.DrugDoseSingle = true;
        this.DrugConfiguration();
      }

    }
  }
  mapview: any = false;
  showcard: any = false;

  ngOnInit() {
    // group number condition
    if (this.GroupNumber === 'GST1016') {
      this.ConditionalId = '48786';
    } else {
      let wholesalername = window.localStorage.getItem('WhName')
      if (wholesalername == "Avenel") {
        this.ConditionalId = "12345"
      }
      else {
        this.ConditionalId = '100-4542';
      }
    }
    window.localStorage.removeItem("SortPharmacy")
    try {
      var str = window.location.href;
      var n = str.lastIndexOf("/");
      var result = str.substring(n + 1);
      if (result == "Pharmacy") {
        $(".navbar-nav").addClass("pad0");
      } else {
        $(".pull-right").find(".navbar-nav").removeClass("pad25");
      }
      if ($(window).width() < 767) {
        $("body").removeAttr("style");
      }
    } catch { }
    // $("body").css({ "pointer-events": "none", opacity: "0.9" });
    $(".type_box").click(function (e) {
      e.stopImmediatePropagation();
      $(".type_box").removeClass("open");
      $(this).toggleClass("open");
      var $this = $(this).parent().find(".type_list");
      $(".filter_box .type_list").not($this).hide().removeClass("list_open");
      $this.toggle();
      $this.toggleClass("list_open");
    });
    $(window).click(function () {
      $(".type_list").hide().removeClass("list_open");
      $(".type_box").removeClass("open");
    });
    $(document).on("clikc", ".collapse_box", function () {
      $(".type_box").removeClass("open");
      $(".type_list").hide().removeClass("list_open");
    });
    // var container_margin_left = $('.container')
    $(document).on("click", ".sortby_ddl ul li", function () {
      $(this).parent().find("li").removeClass("active");
      $(this).addClass("active");
    });
    $(window).on("click", () => {
      this.sortbyddl = false;
      $(".menu_main").removeClass("st-effect-10");
    });
    // ie fix issue
    var ua = window.navigator.userAgent;
    var edge = ua.indexOf("Edge/");
    if (edge > 0) {
      $(".type_list").css({
        "max-height": "137px",
        overflow: "hidden",
        "overflow-y": "scroll",
      });
    }
    if (
      this.Pagename == "Fromheader" &&
      $(".pharmacy_list > div").length == 0
    ) {
      // ;
      this.firstloadpricing = true;
    } else {
      // ;
      this.firstloadpricing = false;
    }
    $(window).resize(() => {
      var header_container = $(".firstloadpricing").css("margin-left");
      var header_container_margin = parseInt(header_container);
      if (this.mapview) {
        $("#pharmacy_container").css({
          "max-width": "100%",
          "padding-left": header_container_margin + 15,
          "padding-right": "0",
        });
      } else {
        $("#pharmacy_container").removeAttr("style");
        $(".pharmacy_list").removeAttr("style");
      }
    });
  }
  hideSortby() {
    this.sortbyddl = !this.sortbyddl;
    $(".type_list").hide();
    $(".menu_main").removeClass("st-effect-10");
  }
  get pharmacy_page(): boolean {
    return this.sidebarService.pharmacy_page;
  }
  hideothermenu() {
    this.sidebarService.togglepharmacy();
    this.sortbyddl = false;
    $(".menu_main").removeClass("st-effect-10");
  }
  Showmapview() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");
    var edge = ua.indexOf("Edge/");
    var ff = ua.indexOf("Firefox");
    if (this.List_Pharmacy_Available == false) {
      return false;
    }
    this.mapview = !this.mapview;
    var header_container = $("#pharmacy_container").css("margin-left");
    var header_container_margin = parseInt(header_container);

    if (this.mapview) {
      this.getBrowser = null;
      this.googleAnalyticsService.eventEmitter(
        "Pharmacy Page",
        "Map View",
        "Show",
        0
      );
      $("#pharmacy_container").css({
        "max-width": "100%",
        "padding-left": header_container_margin + 15,
        "padding-right": "0",
      });
      //if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./) || edge > 0)  // If Internet Explorer, return version number
      // {
      //   this.getBrowser = '';
      //   $('.ie_fixed_add_n').css({ 'position': 'fixed', 'width': '100%', 'z-index': '4' });
      //   $('.ie_fixed_add_pharmacy_header').css({ 'position': 'fixed', 'top': 72, 'width': '100%', 'z-index': '2' });
      //   $('.select_filterList_parent').css({ 'position': 'fixed', 'left': header_container_margin + 15, 'top': 140 });
      //   $('.select_filterList_parent, .type_list').css('min-width', '212px');
      //   $('.pharmacy_list').css({ 'position': 'absolute', 'top': 135, 'min-width': '419px', 'max-width': '419px', 'margin-left': $('.select_filterList_parent').width() + 15 });
      //   var leftvalue = $('.pharmacy_list').width() + parseInt($('.select_filterList_parent').css('left')) + $('.select_filterList_parent').width() + 25;
      //   setTimeout(() => {
      //     $('.maps_box').css({ 'position': 'fixed', 'right': '0', 'left': leftvalue + 'px', 'top': 135 });

      //   }, 600);
      // }
    } else {
      $(
        ".ie_fixed_add_n, .ie_fixed_add_pharmacy_header, .select_filterList_parent, .pharmacy_list, .maps_box"
      ).removeAttr("style");
      this.googleAnalyticsService.eventEmitter(
        "Pharmacy Page",
        "Map View",
        "Hide",
        0
      );
      $("#pharmacy_container").removeAttr("style");
      $(".pharmacy_list").removeAttr("style");
    }
  }
  closerobopopup() {
    this.robopopup=false
  }
  public resolved(captchaResponse): void {
    this.recaptchaStr = captchaResponse;
    if (captchaResponse) {
      try {
        // $(".loaderscreen").addClass("showloader");
        this.showloader = true;
        this.PharmacyList = [];
        this.MarkersList = [];
        this.APICallCount = 0;
        this.PharmacyList_CRX = [];
        this.PharmacyList_DST = [];
        this.PharmacyList_NVT = [];
       
        this.ms
          .GetPharmacyList(
            this._Lat,
            this._Long,
            "",
            this._Quant,
            this._Dose,
            this._Type,
            this._MultiSource,
            this._DrugName,
            this._UserId,
            this._ZipCode,
            this._UserLocation,
            "RXS",
            captchaResponse
          )
          // .pipe(map((res) => res.json()))
          .subscribe(
            (data_CRX) => {
              if(data_CRX[0].IsDKRobo == true) {
                this.robopopup=true
                this.robomsg = data_CRX[0].DKRoboMessage
                this.showloader = false;

              }
              else {
                console.log(data_CRX)
              this.PharmacyList_CRX = data_CRX;
              window.localStorage.setItem("SelectedDrugName", this._DrugName);
              if (this.ms.IsNullOrEmptyField(this._MultiSource) == false) {
                window.localStorage.setItem("IsBG", this._MultiSource);
              }
              if (data_CRX.length > 0) {
                this.PushItemToPharmacyList(data_CRX);
                // this.APICallCount += 1;
                window.localStorage.setItem('Pharmacydata', JSON.stringify(data_CRX))

                // this.showloader = true;
                this.PharmacyList = data_CRX
                this.PharmacyList = this.PharmacyList.sort((a, b) =>
                  a.Price <= b.Price ? -1 : 1
                );
                this.PharmacyList = this.PharmacyList.sort((a, b) =>
                  a.Price <= b.Price ? -1 : 1
                );

                this.IsPricesort = true;
                this.IsDistancesort = false;
              } else {
                this.APICallCount += 1;
              }
              this.LoaderDisplay();

              this.hide_filter_mobile_on();
              }
            },
            (error) => { }
          );
      } catch { }
    }

  }
  onLoginClick(captchaRef: any): void {
    if (this.recaptchaStr) {
      captchaRef.reset();

    }
    captchaRef.execute()
  }
  LoadPharmacyList() {
    $('input[name="captcha"]')[0].click();
    // if (this.recaptchaStr) {
    //   debugger;
    // }


  }

  LoaderDisplay() {
    // if (this.APICallCount >= 4) {
    setTimeout(() => {
      // $(".loaderscreen").removeClass("showloader");
      // ;
      this.NotFoundMessage();
      this.showloader = false;
      $("body").removeAttr("style");
      // ;
      // this.pageEnd = this.pharmacy_list.length;
      // ;
      $(".right_btn_sec .map_btn input")[0].click();
      let size_li = $(".card").length;
      let x = 5;
      $(".card:lt(" + x + ")").show();
      $(".loadmore").click(function () {
        x = x + 5 <= size_li ? x + 5 : size_li;
        $(".card:lt(" + x + ")").show();
        if (x == size_li) {
          $(".loadmore").css("pointer-events", "none");
        }
      });
      $(window).scrollTop($(".pharmacylist_parent").offset().top);
    }, 6000);
    if (this.pharmacy_list.length > 0) {
      this.List_Pharmacy_Available = true;
      setTimeout(() => {

        this.IsPricesort = true;
        this.IsDistancesort = false;
      }, 600);
    } else {
      this.List_Pharmacy_Available = false;
    }
    // }
    // else {
    //   if (!$(".loaderscreen").hasClass("showloader")) {
    //     $(".loaderscreen").addClass("showloader");
    //   }
    // }
  }
  NotFoundMessage() {
    // ;
    if (this.PharmacyList_CRX.length <= 0) {
      setTimeout(() => {
        // ;
        // $(".loaderscreen").removeClass("showloader");
        this.showloader = false;
      }, 600);
      this.PharmacyList = [];
      this.pharmacy_list = [];
      this.MarkersList = [];
      this.APICallCount = 0;
    }
  }
  ClearErrorMessage() {
    this.ZipCodeMessage = "";
    $(".PharmacyNear").removeClass("error_msg");
  }

  GetLatLongByZipCode(ZipCode: string) {
    this.pharmacy_list = [];
    this.PharmacyList = [];
    this.MarkersList = [];
    this.APICallCount = 0;
    let _Zip = "";
    if (String(ZipCode).length == 4) {
      _Zip = "0" + String(ZipCode);
    } else {
      _Zip = String(ZipCode);
    }
    ZipCode = _Zip;
    this._ZipCode = ZipCode;
    this._UserLocation = ZipCode;
    this.ms
      .GetCoordinatebyzip(ZipCode)
      // .pipe(map((res) => res.json()))
      .subscribe((data) => {
        try {
          if (data.results.length > 0) {

            this._UserLocation = data.results[0]["formatted_address"];
            window.localStorage.setItem(
              "UserLat",
              data.results[0].geometry.location.lat
            );
            window.localStorage.setItem(
              "UserLong",
              data.results[0].geometry.location.lng
            );
            window.localStorage.setItem("userZipCode", ZipCode);
            window.localStorage.setItem(
              "UserCurrentAddress",
              this._UserLocation
            );
            this._Lat = data.results[0].geometry.location.lat;
            this._Long = data.results[0].geometry.location.lng;
            this._ZipCode = ZipCode;
            let Pharmacy_Request_Params =
              this._DrugName +
              "|" +
              this._Type +
              "|" +
              this._Dose +
              "|" +
              this._Quant +
              "|" +
              this._MultiSource +
              "|" +
              this._ZipCode;
            this.googleAnalyticsService.eventEmitter(
              "Pharmacy Page",
              "Update Search",
              Pharmacy_Request_Params,
              0
            );
            try {
              this.lat = parseFloat(window.localStorage.getItem("UserLat"));
              this.lng = parseFloat(window.localStorage.getItem("UserLong"));
              this.zoom = 12;
            } catch { }
            this.LoadPharmacyList();
            this.DrugConfiguration();
          } else {

            this.ZipCodeMessage = "Please enter valid zipcode";
            $(".PharmacyNear").addClass("error_msg");
            this.List_Pharmacy_Available = false;
            setTimeout(() => {
              // $(".loaderscreen").removeClass("showloader");
              this.showloader = false;
            }, 600);
            this.ZipCodeMessage = "Please enter valid zipcode";
            this._UserLocation = null;
            window.localStorage.setItem("UserLat", null);
            window.localStorage.setItem("UserLong", null);
            window.localStorage.setItem("userZipCode", "");
            window.localStorage.setItem("UserCurrentAddress", null);
            this.pharmacy_list = [];
            this.PharmacyList = [];
            this.MarkersList = [];
            this.APICallCount = 0;
          }
        } catch { }
      });
  }
  PushItemToPharmacyList(Plist: any[]) {
    try {
      for (let x3 of Plist) {
        let TempItem = {
          Uid: x3.Uid,
          SavedStatus: x3.SavedStatus,
          ResponseType: x3.ResponseType,
          Lat: x3.Lat,
          Long: x3.Long,
          Price: x3.Price,
          DrugName: x3.DrugName,
          Dosage: x3.Dosage,
          MultiSource: x3.MultiSourse,
          Type: x3.Type,
          Quantity: x3.Quantity,
          Address: x3.Address,
          Distance: x3.Distance,
          City: x3.City,
          PharmacyName: x3.PharmacyName,
          Phone: x3.Phone,
          State: x3.State,
          Fdistance: x3.Fdistance,
          CardId: x3.CardId,
          BIN: x3.BIN,
          PCN: x3.PCN,
          PBMPhone: x3.PBMPhone,
          LogoLink: x3.LogoLink,
          SavingsPercent: x3.Savings_Percent,
          RetailPrice: x3.RetailPrice
        };
        this.PharmacyList.push(TempItem);
      }
      this.BindPharmacyList();
    } catch { }
  }
  BindPharmacyList() {
    // try {
    //   this.PharmacyList = this.PharmacyList.sort((a, b) =>
    //     a.Price <= b.Price ? -1 : 1
    //   );
    //   this.PharmacyList = this.PharmacyList.filter(
    //     ((set) => (f) =>
    //       !set.has(f.Address.trim()) && set.add(f.Address.trim()))(new Set())
    //   );
    // } catch { }
    // try {
    //   this._SortType = window.localStorage.getItem("SortPharmacy");
    //   if (this._SortType == "Distance") {
    //     this._SortType = "Distance";
    //     window.localStorage.setItem("SortPharmacy", "Distance");
    //     this.IsDistancesort = true;
    //     this.IsPricesort = false;
    //     this.PharmacyList = this.PharmacyList.sort((a, b) =>
    //       a.Fdistance <= b.Fdistance ? -1 : 1
    //     );
    //   } else {
    //     this._SortType = "Price";
    //     window.localStorage.setItem("SortPharmacy", "Price");
    //     this.IsDistancesort = false;
    //     this.IsPricesort = true;

    //     this.PharmacyList = this.PharmacyList.sort((a, b) =>
    //       a.Price <= b.Price ? -1 : 1
    //     );
    //     this.PharmacyList = this.PharmacyList.sort((a, b) =>
    //       a.Price <= b.Price ? -1 : 1
    //     );
    //   }
    // } catch { }
    window.localStorage.setItem(
      "Pharmacydata",
      JSON.stringify(this.PharmacyList)
    );
    try {
      this.pharmacy_list = this.PharmacyList;
      for (var i = 0; i < this.PharmacyList.length; i++) {
        // this.PharmacyUIDList.push(this.PharmacyList[i]["Uid"]);
        // this.PharmacyPriceList.push('$' + this.PharmacyList[i]["Price"]);
        if (
          this.PharmacyList[i]["Lat"] != "" &&
          this.PharmacyList[i]["Lat"] != null &&
          this.PharmacyList[i]["Lat"] != undefined &&
          this.PharmacyList[i]["Lat"] != "" &&
          this.PharmacyList[i]["Lat"] != null &&
          this.PharmacyList[i]["Lat"] != undefined
        ) {
          this.MarkersList.push({
            lat: this.PharmacyList[i]["Lat"],
            lng: this.PharmacyList[i]["Long"],
            Price: "$" + String(this.PharmacyList[i]["Price"]),
            Id: this.PharmacyList[i]["Uid"],
            DrugName: this.PharmacyList[i]["DrugName"],
            Dosage: this.PharmacyList[i]["Dosage"],
            From: this.PharmacyList[i]["Type"],
            PAddress:
              this.PharmacyList[i]["PharmacyName"] +
              "\r\n" +
              this.PharmacyList[i]["Address"] +
              ", " +
              this.PharmacyList[i]["City"] +
              ", " +
              this.PharmacyList[i]["State"],
          });
          // this.MarkersList.push({ lat: this.PharmacyList[i]["Lat"], lng: this.PharmacyList[i]["Long"], Price: "$" + String(this.PharmacyList[i]["Price"]), Id: this.PharmacyList[i]["Uid"] });
        }
      }
    } catch { }
    if (this.pharmacy_list.length > 0) {
      this.List_Pharmacy_Available = true;
    } else {
      this.List_Pharmacy_Available = false;
    }
  }
  SortPharmacyList(SortParam: string) {
    this.sortbyddl = false;
    $(".menu_main").removeClass("st-effect-10");
    // window.localStorage.setItem("SortPharmacy", SortParam);
    if (SortParam == "Distance") {
      this.PharmacyList = this.PharmacyList.sort((a, b) =>
        a.Fdistance <= b.Fdistance ? -1 : 1
      );
      this.PharmacyList = this.PharmacyList.sort((a, b) =>
        a.Fdistance <= b.Fdistance ? -1 : 1
      );
      this.googleAnalyticsService.eventEmitter(
        "Pharmacy Page",
        "Sort Pharmacy",
        "Distance",
        0
      );
      this.IsPricesort = false;
      this.IsDistancesort = true;
    } else if (SortParam == "Price") {
      this.PharmacyList = this.PharmacyList.sort((a, b) =>
        a.Price <= b.Price ? -1 : 1
      );
      this.PharmacyList = this.PharmacyList.sort((a, b) =>
        a.Price <= b.Price ? -1 : 1
      );
      this.IsPricesort = false;
      this.IsDistancesort = true;
      this.googleAnalyticsService.eventEmitter(
        "Pharmacy Page",
        "Sort Pharmacy",
        "Price",
        0
      );
    }

    this.pharmacy_list = this.PharmacyList;

    window.localStorage.setItem(
      "Pharmacydata",
      JSON.stringify(this.PharmacyList)
    );
    this.MarkersList = [];
    this.lat = parseFloat(window.localStorage.getItem("UserLat"));
    this.lng = parseFloat(window.localStorage.getItem("UserLong"));
    this.zoom = 12;
    for (var i = 0; i < this.PharmacyList.length; i++) {
      if (
        this.PharmacyList[i]["Lat"] != "" &&
        this.PharmacyList[i]["Lat"] != null &&
        this.PharmacyList[i]["Lat"] != undefined &&
        this.PharmacyList[i]["Lat"] != "" &&
        this.PharmacyList[i]["Lat"] != null &&
        this.PharmacyList[i]["Lat"] != undefined
      ) {
        this.MarkersList.push({
          lat: this.PharmacyList[i]["Lat"],
          lng: this.PharmacyList[i]["Long"],
          Price: "$" + String(this.PharmacyList[i]["Price"]),
          Id: this.PharmacyList[i]["Uid"],
          DrugName: this.PharmacyList[i]["DrugName"],
          Dosage: this.PharmacyList[i]["Dosage"],
          From: this.PharmacyList[i]["Type"],
          PAddress:
            this.PharmacyList[i]["PharmacyName"] +
            "\r\n" +
            this.PharmacyList[i]["Address"] +
            ", " +
            this.PharmacyList[i]["City"] +
            ", " +
            this.PharmacyList[i]["State"],
        });
        // this.MarkersList.push({ lat: this.PharmacyList[i]["Lat"], lng: this.PharmacyList[i]["Long"], Price: "$" + String(this.PharmacyList[i]["Price"]), Id: this.PharmacyList[i]["Uid"] });
      }
    }
  }

  DrugConfiguration() {
    this.DrugNameType();
    //this.DrugFormType();

  }
  DrugNameType() {
    try {
      if (
        window.localStorage.getItem("SelectedDrugName") == null ||
        window.localStorage.getItem("SelectedDrugName") == undefined
      ) {
        this._DrugName = "Acetaminophen (Acetaminophen Extra Strength)";
      } else {
        this._DrugName = window.localStorage.getItem("SelectedDrugName");
      }
      if (
        window.localStorage.getItem("IsBG") == null ||
        window.localStorage.getItem("IsBG") == undefined
      ) {
        this._MultiSource = "Generic";
      } else {
        this._MultiSource = window.localStorage.getItem("IsBG");
      }
      if (
        window.localStorage.getItem("DrugType") == null ||
        window.localStorage.getItem("DrugType") == undefined
      ) {
        this._Type = "Tablet";
      } else {
        this._Type = window.localStorage.getItem("DrugType");
      }
      if (
        window.localStorage.getItem("DrugDose") == null ||
        window.localStorage.getItem("DrugDose") == undefined
      ) {
        this._Dose = "325MG";
      } else {
        this._Dose = window.localStorage.getItem("DrugDose");
      }
      if (
        window.localStorage.getItem("DrugQuant") == null ||
        window.localStorage.getItem("DrugQuant") == undefined
      ) {
        this._Quant = "30";
      } else {
        this._Quant = window.localStorage.getItem("displayquantity");
      }
      if (
        window.localStorage.getItem("displayquantity") == null ||
        window.localStorage.getItem("displayquantity") == undefined
      ) {
        window.localStorage.setItem("displayquantity", "30")
      }

      if (
        window.localStorage.getItem("SelectedDrugConfig") == null ||
        window.localStorage.getItem("SelectedDrugConfig") == undefined ||
        JSON.parse(window.localStorage.getItem("SelectedDrugConfig")).length == 0
      ) {

        this.ms
          .GetDrugDetails(this._DrugName)
          // .pipe(map((res) => res.json()))
          .subscribe((data) => {

            this.ConfigResponseData = data;
            window.localStorage.setItem(
              "SelectedDrugConfig",
              JSON.stringify(data)
            );
            this._DrugName = data[0].drugname;
            this._Type = data[0].dose;
            this._Dose = data[0].strength;
            this._Quant = data[0].DisplayQuantity;
            window.localStorage.setItem("ndccode", data[0].ndcode);
            window.localStorage.setItem("DrugQuant", data[0].Quantity);
            window.localStorage.setItem("displayquantity", data[0].DisplayQuantity);
            for (
              let ik = 0;
              ik < this.ConfigResponseData[0]["generic"].length;
              ik++
            ) {
              try {
                if (
                  this.ConfigResponseData[0]["generic"][ik][
                    "sub_drug_name"
                  ].toLowerCase() == this._DrugName.toLowerCase()
                ) {
                }
                this.DrugNameTypeList.push(
                  this.ConfigResponseData[0]["generic"][ik]["sub_drug_name"] +
                  " (Generic Drug)"
                );
              } catch { }
            }
            for (
              let jik = 0;
              jik < this.ConfigResponseData[0]["brand"].length;
              jik++
            ) {
              try {
                if (
                  this.ConfigResponseData[0]["brand"][jik][
                    "sub_drug_name"
                  ].toLowerCase() == this._DrugName.toLowerCase()
                ) {
                }
                this.DrugNameTypeList.push(
                  this.ConfigResponseData[0]["brand"][jik]["sub_drug_name"] +
                  " (Brand Drug)"
                );
              } catch { }
            }
            try {
              if (this.DrugNameTypeList.length <= 1) {
                this.DrugNameTypeSingle = true;
              } else {
                this.DrugNameTypeSingle = false;
              }
            } catch { }
            this.DrugFormType();
          });
      } else {
        this.DrugNameTypeList = [];
        this.ConfigResponseData = JSON.parse(
          window.localStorage.getItem("SelectedDrugConfig")
        );
        for (
          let ik = 0;
          ik < this.ConfigResponseData[0]["generic"].length;
          ik++
        ) {
          try {
            if (
              this.ConfigResponseData[0]["generic"][ik][
                "sub_drug_name"
              ].toLowerCase() == this._DrugName.toLowerCase()
            ) {
            }

            this.DrugNameTypeList.push(
              this.ConfigResponseData[0]["generic"][ik]["sub_drug_name"] +
              " (Generic Drug)"
            );
          } catch { }
        }
        for (
          let jik = 0;
          jik < this.ConfigResponseData[0]["brand"].length;
          jik++
        ) {
          try {
            if (
              this.ConfigResponseData[0]["brand"][jik][
                "sub_drug_name"
              ].toLowerCase() == this._DrugName.toLowerCase()
            ) {
            }
            this.DrugNameTypeList.push(
              this.ConfigResponseData[0]["brand"][jik]["sub_drug_name"] +
              " (Brand Drug)"
            );
          } catch { }
        }
        try {
          if (this.DrugNameTypeList.length <= 1) {
            this.DrugNameTypeSingle = true;
          } else {
            this.DrugNameTypeSingle = false;
          }
        } catch { }
        this.DrugFormType();
      }
    } catch { }
  }
  DrugFormType() {
    try {
      this.DrugFormTypeList = [];
      this.DrugDoseTypeList = [];
      //this.DoseDescriptor_JSON = JSON.parse(this.rs.DrugDoseDescription());
      this.ConfigResponseData = JSON.parse(
        window.localStorage.getItem("SelectedDrugConfig")
      );
      // ;
      if (this.ConfigResponseData.length > 0) {
        // ;

        if (this._MultiSource.toUpperCase() == "BRAND") {
          let _NameFilter = this.ConfigResponseData[0]["brand"].filter(
            (item) => {

              return (
                item["sub_drug_name"].toLowerCase() ==
                this._DrugName.toLowerCase()
              );
            }
          );
          for (let x of _NameFilter[0]["types"]) {

            // let _DoseFilter1 = this.DoseDescriptor_JSON.filter((item) => {
            //   return (
            //     item["Descrption"].toLowerCase() == this._Type.toLowerCase()
            //   );
            // });
            // ;
            //if (_DoseFilter1.length > 0) {
            // if (
            //   x["dose"].toLowerCase() == _DoseFilter1[0]["Dose"].toLowerCase()
            // ) {
            if (x.dose == this._Type) {
              for (let x1 of x["strength"]) {

                this.DrugDoseTypeList.push(x1["Strength"]);
              }
            }
            // }
            //}
          }

          for (let x of _NameFilter[0]["types"]) {
            try {
              // let _DoseFilter = this.DoseDescriptor_JSON.filter((item) => {
              //   return item["Dose"].toLowerCase() == x.dose.toLowerCase();
              // });

              // if (x.dose == this._Type) {
              this.DrugFormTypeList.push({
                value: x.dose,
                text: x.dose,
              });
              // } else {
              //   this.DrugFormTypeList.push({ value: x.dose, text: x.dose });
              // }
            } catch {
              this.DrugFormTypeList.push({ value: x.dose, text: x.dose });
            }
          }
        } else {

          let _NameFilter = this.ConfigResponseData[0]["generic"].filter(
            (item) => {

              return (
                item["sub_drug_name"].toLowerCase() ==
                this._DrugName.toLowerCase()
              );
            }
          );
          // ;
          for (let x of _NameFilter[0]["types"]) {

            // let _DoseFilter1 = this.DoseDescriptor_JSON.filter((item) => {
            //   return (
            //     item["Descrption"].toLowerCase() == this._Type.toLowerCase()
            //   );
            // });
            //if (_DoseFilter1.length > 0) {
            // if (
            //   x["dose"].toLowerCase() == _DoseFilter1[0]["Dose"].toLowerCase()
            // ) {
            if (x.dose == this._Type) {
              for (let x1 of x["strength"]) {

                this.DrugDoseTypeList.push(x1["Strength"]);
              }
            }
            //}
          }

          for (let x of _NameFilter[0]["types"]) {
            try {

              // let _DoseFilter = this.DoseDescriptor_JSON.filter((item) => {
              //   return item["Dose"].toLowerCase() == x.dose.toLowerCase();
              // });
              // if (x.dose == this._Type) {
              this.DrugFormTypeList.push({
                value: x.dose,
                text: x.dose,
              });
              // } else {
              //   this.DrugFormTypeList.push({ value: x.dose, text: x.dose });
              // }
            } catch {
              this.DrugFormTypeList.push({ value: x.dose, text: x.dose });
            }
          }
        }
        this.InitQuantityList();
      }
    } catch { }
    try {
      if (this.DrugFormTypeList.length <= 1) {
        this.DrugFormSingle = true;
      } else {
        this.DrugFormSingle = false;
        if (this._Type == null || this._Type == undefined) {
          this._Type = this.DrugFormTypeList[0].value;
        }
      }
    } catch { }
    try {
      if (this.DrugDoseTypeList.length <= 1) {
        this.DrugDoseSingle = true;
      } else {
        this.DrugDoseSingle = false;
      }
    } catch { }

    if (this.DrugDoseTypeList.length == 0) {
      this.DrugDoseTypeList.push(this._Dose);
    }
    if (this.DrugFormTypeList.length == 0) {
      this.DrugFormTypeList.push({ value: this._Type, text: this._Type });
    }
  }

  InitQuantityList() {
    try {

      this.DrugQuantityTypeList = [];
      this.ConfigResponseData = JSON.parse(
        window.localStorage.getItem("SelectedDrugConfig")
      );
      // let _DoseFilter_1 = this.DoseDescriptor_JSON.filter((item) => {
      //   return item["Descrption"].toLowerCase() == this._Type.toLowerCase();
      // });

      let DoseForm = this._Type;
      let APIResponse = this.ConfigResponseData;
      this._NameFilterForQuantity = [];
      if (this._MultiSource.toLowerCase() == "generic") {
        this._NameFilterForQuantity = APIResponse[0]["generic"].filter(
          (item) => {

            return (
              item["sub_drug_name"].toLowerCase() ==
              this._DrugName.toLowerCase()
            );
          }
        );
      } else {
        this._NameFilterForQuantity = APIResponse[0]["brand"].filter((item) => {

          return (
            item["sub_drug_name"].toLowerCase() == this._DrugName.toLowerCase()
          );
        });
      }
      if (this._NameFilterForQuantity.length > 0) {

        let _DoseFilter = this._NameFilterForQuantity[0]["types"].filter(
          (item) => {

            return item["dose"].toLowerCase() == DoseForm.toLowerCase();
          }
        );
        if (_DoseFilter.length > 0) {

          let _StrengthFilter = _DoseFilter[0]["strength"].filter((item) => {

            return (
              item["Strength"].toLowerCase().replace(/\s/g, "") ==
              this._Dose.toLowerCase().replace(/\s/g, "")
            );
          });
          if (_StrengthFilter.length > 0) {

            for (let xqx of _StrengthFilter[0]["Quantity"]) {

              if (xqx.Quantity != 0) {

                this.DrugQuantityTypeList.push(xqx);
              }
            }
          }
          if (window.localStorage.getItem("IsPresetAvail") == "False") {
            if (this.DrugQuantityTypeList.length > 0) {
              if (
                window.localStorage.getItem("DrugQuant") == "" ||
                window.localStorage.getItem("DrugQuant") == null ||
                window.localStorage.getItem("DrugQuant") == undefined
              ) {

                this._Quant = this.DrugQuantityTypeList[0].DisplayQuantity;
                window.localStorage.setItem('ndccode', this.DrugQuantityTypeList[0].NDCCode);
                window.localStorage.setItem("DrugQuant", this.DrugQuantityTypeList[0].Quantity);
                window.localStorage.setItem("displayquantity", this.DrugQuantityTypeList[0].DisplayQuantity);
              }
              // window.localStorage.setItem("DrugQuant", this._Quant);
            }
            if (
              window.localStorage.getItem("DrugQuant") == "" ||
              window.localStorage.getItem("DrugQuant") == null ||
              window.localStorage.getItem("DrugQuant") == undefined
            ) {
              if (this.DrugQuantityTypeList.length > 0) {

                this._Quant = this.DrugQuantityTypeList[0].DisplayQuantity;
                window.localStorage.setItem('ndccode', this.DrugQuantityTypeList[0].NDCCode);
                window.localStorage.setItem("DrugQuant", this.DrugQuantityTypeList[0].Quantity);
                window.localStorage.setItem("displayquantity", this.DrugQuantityTypeList[0].DisplayQuantity);
              } else {
                //this._Quant = "10";
                window.localStorage.setItem("DrugQuant", this._Quant);
                window.localStorage.setItem("displayquantity", this.DrugQuantityTypeList[0].DisplayQuantity);
              }
            } else {
              this._Quant = window.localStorage.getItem("displayquantity");

            }
          }
        }
      }
    } catch { }
    // if (
    //   this._Quant == undefined ||
    //   this._Quant == "" ||
    //   this._Quant == null ||
    //   this.DrugQuantityTypeList.length == 0
    // ) {
    //   //this.DrugQuant="10";
    //   
    //   window.localStorage.setItem("DrugQuant", this._Quant);
    //   //window.localStorage.setItem("displayquantity", this.DrugQuantityTypeList[0].DisplayQuantity);      
    // }
    // if (this.DrugQuantityTypeList.length == 0) {
    //   
    //   this.DrugQuantityTypeList.push(parseInt(this.DrugQuantityTypeList[0].DisplayQuantity));

    // }
  }

  SelectMapMarker(event, index) {
    this.showshare = index;
    this.UserInputEmail = null;
    this.Card_Pharmacy_Logo = "";
    this.RXS_Walmart = "";
    this.RXS_CVS = "";
    this.SendDiscountMessage = "";
    this.PharmacistHelpline = "Pharmacist Helpline";
    let _phid = event;
    let _NameFilter = this.pharmacy_list.filter((item) => {
      return item["Uid"].toLowerCase() == _phid.toLowerCase();
    });
    if (_NameFilter.length > 0) {
      this.GroupNumber = window.localStorage.getItem('UserGroup');
      this.PharmacyType = _NameFilter[0].ResponseType;
      this.PAddress2 =
        _NameFilter[0].Address +
        ", " +
        _NameFilter[0].City +
        ", " +
        _NameFilter[0].State;
      this.PAddress1 = _NameFilter[0].Address;
      this.PAddress3 = _NameFilter[0].City + ", " + _NameFilter[0].State;
      if (_NameFilter[0].ResponseType == "CRX") {
        this.RXBin = _NameFilter[0].BIN;
        this.RXCPN = _NameFilter[0].PCN;
        this.HelpLine = "877-800-7820";
        this.DialNumber = "8778007820";
      } else if (_NameFilter[0].ResponseType == "NVT") {
        this.RXBin = _NameFilter[0].BIN;
        this.RXCPN = _NameFilter[0].PCN;
        this.HelpLine = "877-800-7820";
        this.DialNumber = "8778007820";
      } else if (_NameFilter[0].ResponseType == "RXS") {
        this.RXBin = _NameFilter[0].BIN;
        this.RXCPN = _NameFilter[0].PCN;
        this.HelpLine = "877-800-7820";
        this.DialNumber = "8778007820";
      } else {
        this.RXBin = _NameFilter[0].BIN;
        this.RXCPN = _NameFilter[0].PCN;
        this.HelpLine = "877-800-7820";
        this.DialNumber = "8778007820";
      }
      this.PharmacyName = _NameFilter[0].PharmacyName;
      if (
        _NameFilter[0].PharmacyName ==
        "use at any major retail pharmacy and save!"
      ) {
        // this.SelectPharmacy = "For " + _NameFilter[0].PharmacyName;
        this.SelectPharmacy = _NameFilter[0].PharmacyName;
      } else {
        // this.SelectPharmacy = "at " + _NameFilter[0].PharmacyName;
        this.SelectPharmacy = _NameFilter[0].PharmacyName;
      }
      if (
        _NameFilter[0].PharmacyName.toLowerCase().indexOf("walmart pharmacy") >
        -1
      ) {
        this.RXBin = _NameFilter[0].BIN;
        this.RXCPN = _NameFilter[0].PCN;
      }
      if (_NameFilter[0].ResponseType == "RXS") {
        // if (
        //   _NameFilter[0].PharmacyName.toLowerCase().indexOf("walmart ") > -1
        // ) {
        this.RXBin = _NameFilter[0].BIN;
        this.RXCPN = _NameFilter[0].PCN;
        this.HelpLine = "877-800-7820";
        this.DialNumber = "8778007820";
        //   this.Card_Pharmacy_Logo = _NameFilter[0].LogoLink;
        //   this.RXS_Walmart = "walmart";
        //   this.Plogo = _NameFilter[0].LogoLink;
        // } else if (
        //   _NameFilter[0].PharmacyName.toLowerCase().indexOf("cvs pharmacy") > -1
        // ) {
        //   this.RXBin = "023518";
        //   this.RXCPN = "ARX";
        //   this.Card_Pharmacy_Logo = _NameFilter[0].LogoLink;
        //   this.RXS_CVS = "cvs";
        //   this.Plogo = _NameFilter[0].LogoLink;
        //}
      }
      // ;
      this.PharmacyPrice = "$" + _NameFilter[0].Price;
      this.PharmacyPrice = this.convertstringtoint(this.PharmacyPrice);
      this.PharmacyPrice = "$" + this.PharmacyPrice.split(" ").join("");

      if (_NameFilter[0].SavingsPercent != '') {
        if (_NameFilter[0].SavingsPercent == undefined) {
          if (_NameFilter[0].Savings_Percent == "") {
            this.Savings_Percent = '';
          } else {
            this.Savings_Percent = 'Save ' + _NameFilter[0].Savings_Percent + '%';
          }
        } else {
          this.Savings_Percent = 'Save ' + _NameFilter[0].SavingsPercent + '%';
        }
        this.RetailPrice = '$' + _NameFilter[0].RetailPrice;
      } else {
        this.Savings_Percent = '';
        this.RetailPrice = '';
      }
      this.PharmacyPhone = _NameFilter[0].Phone;
      this.Card_Dosage = _NameFilter[0].Dosage;
      this.Card_DrugName = _NameFilter[0].DrugName;
      this.Card_Quantity = _NameFilter[0].Quantity;
      this.PharmacyLat = _NameFilter[0].Lat;
      this.PharmacyLng = _NameFilter[0].Long;
      this.Card_Type = _NameFilter[0].Type;
      this.DrugMultiSource = _NameFilter[0].MultiSource;
      this.SelectedCardId = _NameFilter[0].CardId;
      this.PharmacistHL = this.HelpLine;
      this.Card_Pharmacy_Distance = _NameFilter[0].Distance;
      this.Card_CardId = _NameFilter[0].CardId;
      this.Card_PharmacyId = _NameFilter[0].Uid;
      this.GroupNumber = window.localStorage.getItem("UserGroup");
      this.MemberId = window.localStorage.getItem("UserCharId");

      if (
        this.rs.IsLoginRequired() == "Yes" &&
        window.localStorage.getItem("UserId") != null &&
        window.localStorage.getItem("UserId") != "" &&
        window.localStorage.getItem("UserId") != undefined &&
        window.localStorage.getItem("UserId") != "null"
      ) {
        this.DiscountCardSavedStatus_login(
          this._UserId,
          this.MemberId,
          this.PharmacyName,
          _NameFilter[0].ResponseType,
          _NameFilter[0].DrugName,
          _NameFilter[0].Price,
          _NameFilter[0].Quantity,
          _NameFilter[0].Dosage,
          this.Card_DrugForm,
          this.PharmacyPhone,
          this.PAddress1,
          this.PAddress3,
          this.DrugMultiSource
        );
      } else if (
        this.rs.IsLoginRequired() == "No" &&
        window.localStorage.getItem("UserId") != null &&
        window.localStorage.getItem("UserId") != undefined
      ) {
        this.DiscountCardSavedStatus_login(
          this._UserId,
          this.MemberId,
          this.PharmacyName,
          _NameFilter[0].ResponseType,
          _NameFilter[0].DrugName,
          _NameFilter[0].Price,
          _NameFilter[0].Quantity,
          _NameFilter[0].Dosage,
          this.Card_DrugForm,
          this.PharmacyPhone,
          this.PAddress1,
          this.PAddress3,
          this.DrugMultiSource
        );
      } else if (
        this.rs.IsLoginRequired() == "Yes" &&
        window.localStorage.getItem("SkipSignup") == "signupskipped"
      ) {
        window.localStorage.setItem("SelectedPhamacyId", _NameFilter[0].Uid);
        this.IsSave = this.DiscountCardSavedStatus(
          _NameFilter[0].Uid,
          _NameFilter[0].CardId,
          this.Card_DrugName,
          this.PharmacyPrice,
          this.Card_Type,
          this.Card_Dosage
        );
        this.showcard = true;
      } else {
        window.localStorage.setItem("SelectedPhamacyId", _NameFilter[0].Uid);
        this.IsSave = this.DiscountCardSavedStatus(
          _NameFilter[0].Uid,
          _NameFilter[0].CardId,
          this.Card_DrugName,
          this.PharmacyPrice,
          this.Card_Type,
          this.Card_Dosage
        );
        this.showcard = true;
      }
      // this.showcard = true;
      // $('body').css('overflow', 'hidden');
    }
  }
  convertstringtoint(price) {
    var s1 = price;
    var s2 = s1.substr(1);
    var numberValue = Number(s2);
    var res = s2.split(".");
    if (res.length == 1 || res[1].length < 3) {
      var pricevalue = numberValue.toFixed(2);
    }
    return pricevalue;
  }
  showDiscountcard(item: any, index, savingsPercent, retailPrice) {
    window.localStorage.setItem('cardIndex', index);
    this.UserInputEmail = null;
    this.Card_Pharmacy_Logo = "";
    this.RXS_Walmart = "";
    this.RXS_CVS = "";
    this.UserInputEmail = null;
    this.SendDiscountMessage = "";
    this.PharmacistHelpline = "Pharmacist Helpline";
    this.GroupNumber = window.localStorage.getItem("UserGroup");
    this.PharmacyType = item.ResponseType;
    this.PAddress2 = item.Address.trim() + ", " + item.City + ", " + item.State;
    this.PAddress1 = item.Address.trim();
    this.PAddress3 = item.City + ", " + item.State;
    // this.Bin = item.BIN;
    // this.Pcn = item.PCN;
    // this.DialNumber = item.PBMPhone;
    this.Plogo = "";

    // if (item.ResponseType == "CRX") {
    //   this.RXBin = "015284";
    //   this.RXCPN = "CRX";
    //   this.HelpLine = "888-680-0827";
    //   this.DialNumber = "8886800827";
    // } else if (item.ResponseType == "NVT") {
    //   this.RXBin = "610602";
    //   this.RXCPN = "HDX";
    //   this.HelpLine = "866-809-9382";
    //   this.DialNumber = "8668099382";
    // } else if (item.ResponseType == "RXS") {
    //   this.RXBin = "023518";
    //   this.RXCPN = "ARX";
    //   this.HelpLine = "877-800-7820";
    //   this.DialNumber = "8778007820";
    // } else {
    //   this.RXBin = "600428";
    //   this.RXCPN = "HRX";
    //   this.HelpLine = "844-616-9490";
    //   this.DialNumber = "8446169490";
    // }
    this.PharmacyName = item.PharmacyName;
    if (item.PharmacyName == "use at any major retail pharmacy and save!") {
      // this.SelectPharmacy = "For " + item.PharmacyName;
      this.SelectPharmacy = item.PharmacyName;
    } else {
      // this.SelectPharmacy = "at " + item.PharmacyName;
      this.SelectPharmacy = item.PharmacyName;
    }
    if (
      item.ResponseType != "RXS" &&
      item.PharmacyName.toLowerCase().indexOf("walmart pharmacy") > -1
    ) {
      this.RXBin = item.BIN;
      this.RXCPN = item.PCN;
    }
    if (item.ResponseType == "RXS") {
      // if (item.PharmacyName.toLowerCase().indexOf("walmart ") > -1) {
      this.RXBin = item.BIN;
      this.RXCPN = item.PCN;
      this.HelpLine = "877-800-7820";
      this.DialNumber = "8778007820";
      // this.Card_Pharmacy_Logo = item.LogoLink;
      // this.RXS_Walmart = "walmart";
      // this.Plogo = item.LogoLink;
      // } else if (item.PharmacyName.toLowerCase().indexOf("cvs pharmacy") > -1) {
      // this.RXBin = "023518";
      // this.RXCPN = "ARX";
      // this.Card_Pharmacy_Logo = item.LogoLink;
      // this.RXS_CVS = "cvs";
      // this.Plogo = item.LogoLink;
      //}
    }
    // ;
    // this.PharmacyPrice = "$" + item.Price;
    this.PharmacyPrice = $(".name_Sec .item-price").eq(index).text();
    if (savingsPercent != '') {
      this.Savings_Percent = 'Save ' + savingsPercent + '%';
      this.RetailPrice = '$' + retailPrice;
    } else {
      this.Savings_Percent = '';
      this.RetailPrice = '';
    }
    this.PharmacyPhone = item.Phone;
    this.Card_Dosage = item.Dosage;
    this.Card_DrugName = item.DrugName;
    this.Card_Quantity = item.DisplayQuantity;
    this.PharmacyLat = item.Lat;
    this.PharmacyLng = item.Long;
    this.DrugMultiSource = item.MultiSource;
    this.SelectedCardId = item.CardId;
    this.PharmacistHL = this.HelpLine;
    this.Card_Type = item.Type;
    this.Card_Pharmacy_Distance = item.Distance;
    this.Card_CardId = item.CardId;
    this.Card_PharmacyId = item.Uid;
    this.Card_UID = item.Uid;
    // group number condition
    let member_Id = '100-4542';
    let wholesalername = window.localStorage.getItem('WhName')
    if (wholesalername == "Avenel" && this.GroupNumber != 'GST1016') {
      member_Id = "12345"
    }
    if (this.GroupNumber === 'GST1016') {
      member_Id = '48786';
    }
    this.MemberId = member_Id;
    window.localStorage.setItem('UserCharId', member_Id)

    if (
      this.rs.IsLoginRequired() == "Yes" &&
      window.localStorage.getItem("UserId") != null &&
      window.localStorage.getItem("UserId") != "" &&
      window.localStorage.getItem("UserId") != undefined &&
      window.localStorage.getItem("UserId") != "null"
    ) {
      this.DiscountCardSavedStatus_login(
        this._UserId,
        this.MemberId,
        this.PharmacyName,
        item.ResponseType,
        item.DrugName,
        item.Price,
        item.Quantity,
        item.Dosage,
        this.Card_Type,
        this.PharmacyPhone,
        this.PAddress1,
        this.PAddress3,
        this.DrugMultiSource
      );
    } else if (
      this.rs.IsLoginRequired() == "No" &&
      window.localStorage.getItem("UserId") != null &&
      window.localStorage.getItem("UserId") != undefined
    ) {
      this.DiscountCardSavedStatus_login(
        this._UserId,
        this.MemberId,
        this.PharmacyName,
        item.ResponseType,
        item.DrugName,
        item.Price,
        item.Quantity,
        item.Dosage,
        this.Card_Type,
        this.PharmacyPhone,
        this.PAddress1,
        this.PAddress3,
        this.DrugMultiSource
      );
    } else if (
      this.rs.IsLoginRequired() == "Yes" &&
      window.localStorage.getItem("SkipSignup") == "signupskipped"
    ) {
      window.localStorage.setItem("SelectedPhamacyId", item.Uid);
      this.IsSave = this.DiscountCardSavedStatus(
        item.Uid,
        item.CardId,
        this.Card_DrugName,
        this.PharmacyPrice,
        this.Card_Type,
        this.Card_Dosage
      );
      this.showcard = true;
    } else {
      window.localStorage.setItem("SelectedPhamacyId", item.Uid);
      this.IsSave = this.DiscountCardSavedStatus(
        item.Uid,
        item.CardId,
        this.Card_DrugName,
        this.PharmacyPrice,
        this.Card_Type,
        this.Card_Dosage
      );
      this.showcard = true;
    }
    // this.showcard = true;
    // $('body').css('overflow', 'hidden');
  }
  CloseDrugDetails() {
    this.showcard = false;
    $("body").removeAttr("style");
  }

  //-------------------Swap Drug name, Drug Type, Drug Dose, Drug Quantity from left panel---------------------------
  SwapDrugname(DrugName: string) {
    this.googleAnalyticsService.eventEmitter(
      "Pharmacy Page",
      "Swap Drug Name",
      DrugName,
      0
    );
    let sdrug = DrugName.replace(" (Generic Drug)", "")
      .replace(" (Brand Drug)", "")
      .replace(" (Mod)", "")
      .trim();
    if (DrugName.indexOf("Generic Drug") > -1) {

      this.Swap_Drug_Name = sdrug;
      this.Swap_Drug_MultiSource = "Generic";
      window.localStorage.setItem("IsBG", "Generic");
      this._MultiSource = "Generic";
      this.Swap_Drug_With_MultiSource_New = DrugName;
      this._DrugName = sdrug;
    } else {

      this._DrugName = sdrug;
      this.Swap_Drug_Name = sdrug;
      this.Swap_Drug_MultiSource = "Brand";
      window.localStorage.setItem("IsBG", "Brand");
      this._MultiSource = "Brand";
      this.Swap_Drug_With_MultiSource_New = DrugName;
    }
    this.UpdateDrugParamsWithSwaping();
  }

  SwapDrugForm(DrugType: string) {
    this.googleAnalyticsService.eventEmitter(
      "Pharmacy Page",
      "Swap Drug Form",
      this._Type + "<--->" + DrugType,
      0
    );
    let sdrug = this.Swap_Drug_With_MultiSource_New.replace(
      " (Generic Drug)",
      ""
    )
      .replace(" (Brand Drug)", "")
      .replace(" (Mod)", "")
      .trim();
    this.Swap_Drug_Name = sdrug;
    if (this.Swap_Drug_With_MultiSource_New.indexOf("Generic Drug") > -1) {
      this.Swap_Drug_MultiSource = "Generic";
    } else {
      this.Swap_Drug_MultiSource = "Brand";
    }
    this.UpdateDoseByFormTypeSwaping(DrugType);
  }
  SwapDrugDose(_DrugDose: string) {
    this.googleAnalyticsService.eventEmitter(
      "Pharmacy Page",
      "Swap Drug Dose",
      this._Dose + "<--->" + _DrugDose,
      0
    );
    this._Dose = _DrugDose;
    this.UpdateDrugQuantityOnTopChangebydose(_DrugDose);
  }
  SwapDrugQuantity(_DrugQuantity: string, ndccode, displayquantity) {
    this.googleAnalyticsService.eventEmitter(
      "Pharmacy Page",
      "Swap Drug Quantity",
      this._Quant + "<--->" + _DrugQuantity,
      0
    );
    this._Quant = displayquantity;
    window.localStorage.setItem('ndccode', ndccode);
    window.localStorage.setItem("DrugQuant", _DrugQuantity);
    window.localStorage.setItem("displayquantity", displayquantity);

  }
  UpdateDrugParamsWithSwaping() {
    try {
      //this.DoseDescriptor_JSON = JSON.parse(this.rs.DrugDoseDescription());
      this.ConfigResponseData = JSON.parse(
        window.localStorage.getItem("SelectedDrugConfig")
      );
      if (this.Swap_Drug_MultiSource.toLowerCase() == "generic") {
        let FilterItem = this.ConfigResponseData[0]["generic"].filter(
          (item) => {

            return (
              item["sub_drug_name"].toLowerCase() ==
              this.Swap_Drug_Name.toLowerCase()
            );
          }
        );
        if (FilterItem.length > 0) {

          this.DrugFormTypeList = [];
          for (let x of FilterItem[0]["types"]) {

            try {
              // let _DoseFilter = this.DoseDescriptor_JSON.filter((item) => {
              //   return item["Dose"].toLowerCase() == x.dose.toLowerCase();
              // });
              //if (_DoseFilter.length > 0) {
              this.DrugFormTypeList.push({
                value: x.dose,
                text: x.dose,
              });
              // } else {
              //   this.DrugFormTypeList.push({ value: x.dose, text: x.dose });
              // }
            } catch {
              this.DrugFormTypeList.push({ value: x.dose, text: x.dose });
            }
          }

          this.UpdateDoseByFormTypeSwaping(this.DrugFormTypeList[0].value);
        }
      } else {
        let FilterItem = this.ConfigResponseData[0]["brand"].filter((item) => {
          return (
            item["sub_drug_name"].toLowerCase() ==
            this.Swap_Drug_Name.toLowerCase()
          );
        });
        if (FilterItem.length > 0) {
          this.DrugFormTypeList = [];
          for (let x of FilterItem[0]["types"]) {
            try {
              // let _DoseFilter = this.DoseDescriptor_JSON.filter((item) => {
              //   return item["Dose"].toLowerCase() == x.dose.toLowerCase();
              // });
              // if (_DoseFilter.length > 0) {
              this.DrugFormTypeList.push({
                value: x.dose,
                text: x.dose,
              });
              // } else {
              //   this.DrugFormTypeList.push({ value: x.dose, text: x.dose });
              // }
            } catch {
              this.DrugFormTypeList.push({ value: x.dose, text: x.dose });
            }
          }
          this.UpdateDoseByFormTypeSwaping(this.DrugFormTypeList[0].value);
        }
      }
      this._Type = this.DrugFormTypeList[0].value;
    } catch { }

    try {
      if (this.DrugFormTypeList.length <= 1) {
        this.DrugFormSingle = true;
      } else {
        this.DrugFormSingle = false;
      }
    } catch { }
  }
  UpdateDoseByFormTypeSwaping(_doseform) {
    //this.DoseDescriptor_JSON = JSON.parse(this.rs.DrugDoseDescription());
    this.Swap_Drug_Form = _doseform;
    this._Type = _doseform;
    // this.Card_DrugForm = _doseform;
    this.ConfigResponseData = JSON.parse(
      window.localStorage.getItem("SelectedDrugConfig")
    );
    let tempdoseform = "";
    // try {
    //   // let _DoseFilter = this.DoseDescriptor_JSON.filter((item) => {
    //   //   return item["Descrption"].toLowerCase() == _doseform.toLowerCase();
    //   // });
    //   if (_DoseFilter.length > 0) {
    //     tempdoseform = _DoseFilter[0]["Dose"];
    //   } else {
    //     tempdoseform = _doseform;
    //   }
    // } catch {
    tempdoseform = _doseform;
    // }
    this.DrugDoseTypeList = [];
    try {
      if (this.ConfigResponseData.length > 0) {
        if (this.Swap_Drug_MultiSource.toLowerCase() == "generic") {
          let _NameFilter = this.ConfigResponseData[0]["generic"].filter(
            (item) => {
              return (
                item["sub_drug_name"].toLowerCase() ==
                this.Swap_Drug_Name.toLowerCase()
              );
            }
          );
          let _it = -1;
          for (let x of _NameFilter[0]["types"]) {
            try {
              _it += 1;
              if (x.dose == tempdoseform) {
                for (let x of _NameFilter[0]["types"][_it]["strength"]) {
                  if (this.ms.IsNullOrEmptyField(x["Strength"]) == false) {
                    this.DrugDoseTypeList.push(x["Strength"]);
                  }
                }
                break;
              }
            } catch { }
          }
        } else {
          let _NameFilter = this.ConfigResponseData[0]["brand"].filter(
            (item) => {
              return (
                item["sub_drug_name"].toLowerCase() ==
                this.Swap_Drug_Name.toLowerCase()
              );
            }
          );
          let _it = -1;
          for (let x of _NameFilter[0]["types"]) {
            try {
              _it += 1;
              if (x.dose == tempdoseform) {
                for (let x of _NameFilter[0]["types"][_it]["strength"]) {
                  if (this.ms.IsNullOrEmptyField(x["Strength"]) == false) {
                    this.DrugDoseTypeList.push(x["Strength"]);
                  }
                }
                break;
              }
            } catch { }
          }
        }
        if (this.DrugDoseTypeList.length > 0) {
          this.Swap_Drug_Dose = this.DrugDoseTypeList[0].replace(" ", "");
          this._Dose = this.Swap_Drug_Dose;
        } else {
          this._Dose = "";
        }
        // this.DrugDose = this.DrugDoseList[0].replace(' ', '');
        // window.localStorage.setItem('DrugDose', this.DrugDose);
        // window.localStorage.setItem('DrugType', _doseform);
        // this.selectedValue = _doseform;
        // if (this.DrugDoseList.length <= 1) {
        //   this.StrengthSingle = true;
        // }
        // else {
        //   this.StrengthSingle = false;
        // }
      }
    } catch { }
    this.UpdateDrugQuantityOnTopChange(_doseform);
    try {
      if (this.DrugDoseTypeList.length <= 1) {
        this.DrugDoseSingle = true;
      } else {
        this.DrugDoseSingle = false;
      }
    } catch { }
  }
  UpdateDrugQuantityOnTopChange(DoseFormm) {

    try {
      this.ConfigResponseData = JSON.parse(
        window.localStorage.getItem("SelectedDrugConfig")
      );
      this.DrugQuantityTypeList = [];
      // let _DoseFilter_1 = this.DoseDescriptor_JSON.filter((item) => {
      //   return (
      //     item["Descrption"].toLowerCase() == this.Swap_Drug_Form.toLowerCase()
      //   );
      // });
      let DoseForm = DoseFormm;
      let APIResponse = this.ConfigResponseData;
      this._NameFilterForQuantity = [];
      if (this.Swap_Drug_MultiSource.toLowerCase() == "generic") {
        this._NameFilterForQuantity = APIResponse[0]["generic"].filter(
          (item) => {
            return (
              item["sub_drug_name"].toLowerCase() ==
              this.Swap_Drug_Name.toLowerCase()
            );
          }
        );
      } else {
        this._NameFilterForQuantity = APIResponse[0]["brand"].filter((item) => {
          return (
            item["sub_drug_name"].toLowerCase() ==
            this.Swap_Drug_Name.toLowerCase()
          );
        });
      }
      if (this._NameFilterForQuantity.length > 0) {
        let _DoseFilter = this._NameFilterForQuantity[0]["types"].filter(
          (item) => {

            return item["dose"].toLowerCase() == DoseForm.toLowerCase();
          }
        );
        if (_DoseFilter.length > 0) {
          let _StrengthFilter = _DoseFilter[0]["strength"].filter((item) => {

            return (
              item["Strength"].toLowerCase().replace(/\s/g, "") ==
              this.Swap_Drug_Dose.toLowerCase().replace(/\s/g, "")
            );
          });
          for (let xqx of _StrengthFilter[0]["Quantity"]) {
            if (xqx.Quantity != 0) {

              this.DrugQuantityTypeList.push(xqx);
            }
          }
          this.DrugQuantityTypeList.sort(function (a, b) {
            return a - b;
          });
          if (this.DrugQuantityTypeList.length > 0) {
            this._Quant = this.DrugQuantityTypeList[0].DisplayQuantity;
            window.localStorage.setItem('ndccode', this.DrugQuantityTypeList[0].NDCCode);
            window.localStorage.setItem("DrugQuant", this.DrugQuantityTypeList[0].Quantity);
            window.localStorage.setItem("displayquantity", this.DrugQuantityTypeList[0].DisplayQuantity);
          } else {
            this._Quant = "10";
          }
          // window.localStorage.setItem("DrugQuant", this._Quant);
          // window.localStorage.setItem("displayquantity", this.DrugQuantityTypeList[0].DisplayQuantity);
        }
      }
    } catch { }
  }
  UpdateDrugQuantityOnTopChangebydose(dosee) {

    try {
      this.ConfigResponseData = JSON.parse(
        window.localStorage.getItem("SelectedDrugConfig")
      );
      this.DrugQuantityTypeList = [];
      // let _DoseFilter_1 = this.DoseDescriptor_JSON.filter((item) => {
      //   return (
      //     item["Descrption"].toLowerCase() == this.Swap_Drug_Form.toLowerCase()
      //   );
      // });
      let selectedmedicine = this._DrugName;
      let form = this._Type;
      let dose = dosee;
      let APIResponse = this.ConfigResponseData;
      this._NameFilterForQuantity = [];
      if (window.localStorage.getItem('IsBG').toLowerCase() == "generic") {
        this._NameFilterForQuantity = APIResponse[0]["generic"].filter(
          (item) => {

            return (
              item["sub_drug_name"].toLowerCase() ==
              selectedmedicine.toLowerCase()
            );
          }
        );
      } else {
        this._NameFilterForQuantity = APIResponse[0]["brand"].filter((item) => {
          return (
            item["sub_drug_name"].toLowerCase() ==
            selectedmedicine.toLowerCase()
          );
        });
      }
      if (this._NameFilterForQuantity.length > 0) {
        let _DoseFilter = this._NameFilterForQuantity[0]["types"].filter(
          (item) => {

            return item["dose"].toLowerCase() == form.toLowerCase();
          }
        );
        if (_DoseFilter.length > 0) {
          let _StrengthFilter = _DoseFilter[0]["strength"].filter((item) => {

            return (
              item["Strength"].toLowerCase().replace(/\s/g, "") ==
              dose.toLowerCase().replace(/\s/g, "")
            );
          });
          for (let xqx of _StrengthFilter[0]["Quantity"]) {
            if (xqx.Quantity != 0) {

              this.DrugQuantityTypeList.push(xqx);
            }
          }
          this.DrugQuantityTypeList.sort(function (a, b) {
            return a - b;
          });
          if (this.DrugQuantityTypeList.length > 0) {
            this._Quant = this.DrugQuantityTypeList[0].DisplayQuantity;
            window.localStorage.setItem('ndccode', this.DrugQuantityTypeList[0].NDCCode);
            window.localStorage.setItem("DrugQuant", this.DrugQuantityTypeList[0].Quantity);
            window.localStorage.setItem("displayquantity", this.DrugQuantityTypeList[0].DisplayQuantity);
          } else {
            this._Quant = "10";
          }
          // window.localStorage.setItem("DrugQuant", this._Quant);
          // window.localStorage.setItem("displayquantity", this.DrugQuantityTypeList[0].DisplayQuantity);
        }
      }
    } catch { }
  }
  OpenQuantity_Box() {
    this.Quantity_Manual = null;
    this.ManualQuantity_box = true;
  }
  SubmitQuantity() {
    if (
      this.Quantity_Manual == null ||
      this.Quantity_Manual == "" ||
      this.Quantity_Manual == undefined
    ) {
      this.Quantity_error = "Please enter a quantity";
      $("#Quantity_Manual").parent().addClass("error_msg");
      return false;
    } else {
      this.ManualQuantity_box = false;
      this._Quant = this.Quantity_Manual;
      window.localStorage.setItem("DrugQuant", this._Quant);
    }
  }
  DrugQuantityValidate(e: any) {
    this.Quantity_error = "";
    $("#Quantity_Manual").parent().removeClass("error_msg");
    var keyCode = e.which ? e.which : e.keyCode;
    if (keyCode != 8 && keyCode != 0 && (keyCode < 48 || keyCode > 57)) {
      return false;
    }
  }
  ZipCodeValidate(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  checkLoginState() {
    var user_email = window.localStorage.getItem("UserEmail");
    var group_number = window.localStorage.getItem("UserGroup");
    var first_name = window.localStorage.getItem("FirstName");
    var last_name = window.localStorage.getItem("LastName");

    if (!!user_email && !!first_name && !!last_name && !!group_number) {
      return true
    } else {
      return false
    }
  }
  UpdatePharmacyList() {
    if (this.mapview == true) {
      $("#locate").click();
    }
    let getLenth = this._ZipCode.length;
    if (getLenth < 5) {
      this.ZipCodeMessage = "Please enter valid zipcode";
      $(".PharmacyNear").addClass("error_msg");
      return false;
    }
    this.pharmacy_list = [];
    this.PharmacyList = [];
    this.MarkersList = [];
    this.APICallCount = 0;
    this.Enter_Zip = false;
    this.ZipCodeMessage = null;
    this.List_Pharmacy_Available = true;
    this._Quant = window.localStorage.getItem("DrugQuant")

    if (this.ms.IsNullOrEmptyField(this._ZipCode) == true) {
      this.ZipCodeMessage = "Please enter valid zipcode";
      setTimeout(() => {
        // $(".loaderscreen").removeClass("showloader");
        this.showloader = false;
      }, 600);
      return false;
    } else {
      // $("body").css({ "pointer-events": "none", opacity: "0.9" });
      // $(".loaderscreen").addClass("showloader");
      this.showloader = true;
      window.localStorage.setItem("DrugQuant", this._Quant);
      window.localStorage.setItem("DrugDose", this._Dose);
      window.localStorage.setItem("DrugType", this._Type);
      if (this.ms.IsNullOrEmptyField(this.Swap_Drug_Name) == true) {
        window.localStorage.setItem("SelectedDrugName", this._DrugName);
        this.Swap_Drug_Name = this._DrugName;
      } else {
        window.localStorage.setItem("SelectedDrugName", this.Swap_Drug_Name);
      }
      // this.Card_DrugForm = this._Type;
      if (this.ms.IsNullOrEmptyField(this.Swap_Drug_MultiSource) == false) {
        window.localStorage.setItem("IsBG", this.Swap_Drug_MultiSource);
      } else {
        if (this.ms.IsNullOrEmptyField(this._MultiSource) == false) {
          window.localStorage.setItem("IsBG", this._MultiSource);
          this.Swap_Drug_MultiSource = this._MultiSource;
        } else {
          this._MultiSource = this.Swap_Drug_MultiSource = window.localStorage.getItem(
            "IsBG"
          );
        }
      }
      this._CurrLocation = this._ZipCode.toString();
      this._UserZip = this._ZipCode;
      this.GetLatLongByZipCode(this._ZipCode);
      this.firstloadpricing = false;
    }

  }
  RedirectGoogleDirection() {
    try {
      let DirectionLink =
        "https://www.google.com/maps/dir/?api=1&origin=" +
        this._Lat +
        "," +
        this._Long +
        "&destination=" +
        this.PharmacyLat +
        "," +
        this.PharmacyLng;
      window.open(DirectionLink, "_blank");
    } catch { }
  }

  SaveCard(event) {

    if (this.checkLoginState()) {
      this.PharmacyPrice = $(event.target)
        .parent()
        .parent()
        .find(".pharmacybox")
        .find(".price")
        .text();
      this.PharmacyPrice = this.PharmacyPrice.split(" ").join("");
      if (this.MemberId == null || this.MemberId == undefined) {
        this.MemberId = window.localStorage.getItem('UserCharId');
        if (
          window.localStorage.getItem("UserCharId") == null ||
          window.localStorage.getItem("UserCharId") == undefined
        ) {
          window.localStorage.setItem("UserCharId", this.MemberId);
        }
      }
      if (this.IsSave != "Remove") {
        if (
          this.rs.IsLoginRequired() == "Yes" &&
          window.localStorage.getItem("UserId") != null &&
          window.localStorage.getItem("UserId") != "" &&
          window.localStorage.getItem("UserId") != undefined &&
          window.localStorage.getItem("UserId") != "null"
        ) {
          this.SaveUserSearchCards(
            window.localStorage.getItem("UserId"),
            window.localStorage.getItem("UserCharId"),
            this.PharmacyName,
            this.PharmacyType,
            this.Card_DrugName,
            this.PharmacyPrice,
            this.Card_Quantity,
            this.Card_Dosage,
            this.Card_Type,
            this.PharmacyPhone,
            this.PAddress1,
            this.PAddress3,
            "",
            this.DrugMultiSource
          );
          //this.IsSave = "Remove";
          window.localStorage.setItem("SavedStatus", "Remove");
        } else if (
          this.rs.IsLoginRequired() == "No" &&
          window.localStorage.getItem("UserId") != null &&
          window.localStorage.getItem("UserId") != undefined
        ) {
          this.SaveUserSearchCards(
            window.localStorage.getItem("UserId"),
            window.localStorage.getItem("UserCharId"),
            this.PharmacyName,
            this.PharmacyType,
            this.Card_DrugName,
            this.PharmacyPrice,
            this.Card_Quantity,
            this.Card_Dosage,
            this.Card_Type,
            this.PharmacyPhone,
            this.PAddress1,
            this.PAddress3,
            "",
            this.DrugMultiSource
          );
          //this.IsSave = "Remove";
          window.localStorage.setItem("SavedStatus", "Remove");
        } else {
          try {
            if (
              window.localStorage.getItem("CardListLocally") != null ||
              window.localStorage.getItem("CardListLocally") != undefined
            ) {
              let CardListData = JSON.parse(
                window.localStorage.getItem("CardListLocally")
              );
              this.SavedCardList = [];
              for (let x3 of CardListData) {
                if (x3.CardId == undefined) {
                  x3.CardId = "-1";
                }
                let TempItem = {
                  PharmacyType: x3.PharmacyType,
                  UserGroup: window.localStorage.getItem('UserGroup'),
                  PharmacyPhone: x3.PharmacyPhone,
                  UserCharId: x3.UserCharId,
                  PharmacyName: x3.PharmacyName,
                  Address1: x3.Address1,
                  Address2: x3.Address2,
                  DrugName: x3.DrugName,
                  Price: x3.Price,
                  Quantity: x3.Quantity,
                  Dose: x3.Dose,
                  Form: x3.Form,
                  GPId: x3.GPId,
                  MultiSource: x3.MultiSource,
                  Distance: x3.Distance,
                  CardId: x3.CardId,
                  PharmacyId: x3.PharmacyId,
                  BIN: x3.BIN,
                  PCN: x3.PCN,
                  PBMPhone: x3.PBMPhone,
                  LogoLink: x3.LogoLink,
                };
                this.SavedCardList.push(TempItem);
              }
            }

            let newItem = {
              PharmacyType: this.PharmacyType,
              UserGroup: window.localStorage.getItem('UserGroup'),
              PharmacyPhone: this.PharmacyPhone,
              UserCharId: this.MemberId,
              PharmacyName: this.PharmacyName,
              Address1: this.PAddress1,
              Address2: this.PAddress3,
              DrugName: this.Card_DrugName,
              Price: this.PharmacyPrice,
              Quantity: this.Card_Quantity,
              Dose: this.Card_Dosage,
              Form: this.Card_Type,
              GPId: "",
              MultiSource: this.DrugMultiSource,
              Distance: this.Card_Pharmacy_Distance,
              CardId: this.Card_CardId,
              PharmacyId: this.Card_PharmacyId,
              BIN: this.RXBin,
              PCN: this.RXCPN,
              PBMPhone: this.DialNumber,
              LogoLink: this.Plogo,
            };

            this.SavedCardList.push(newItem);
            // let myArrStr = JSON.stringify(this.SavedCardList);
            window.localStorage.setItem(
              "CardListLocally",
              JSON.stringify(this.SavedCardList)
            );
            this.IsSave = "Remove";
            window.localStorage.setItem("SavedStatus", "Remove");
            window.localStorage.setItem("SelectedCard", "-1");
            window.localStorage.setItem(
              "SelectedPhamacyId",
              this.Card_PharmacyId
            );
          } catch { }
        }
      } else {
        if (
          this.rs.IsLoginRequired() == "Yes" &&
          window.localStorage.getItem("UserId") != null &&
          window.localStorage.getItem("UserId") != "" &&
          window.localStorage.getItem("UserId") != undefined &&
          window.localStorage.getItem("UserId") != "null"
        ) {
          this.ms
            .RemoveDiscountCard(
              window.localStorage.getItem("UserId"),
              this.MemberId,
              this.Card_DrugName,
              this.Card_Quantity,
              this.Card_Type,
              this.Card_Dosage,
              this.PharmacyPrice,
              this.PharmacyName,
              this.PAddress1,
              this.PAddress3,
              this.PharmacyPhone,
              this.DrugMultiSource,
              this.PharmacyType
            )
            // .pipe(map((res) => res.json()))
            .subscribe((data) => {
              if (data.length > 0) {
                if ((data[0].Status = "Success")) {
                  this.IsSave = "Save";
                  $(".button_sec > div:first-child")
                    .addClass("save")
                    .removeClass("saved");
                  this.RemoveDiscountCard_login(
                    this.SelectedCardId,
                    this.Card_PharmacyId
                  );
                }
              }
            });
        } else if (
          this.rs.IsLoginRequired() == "No" &&
          window.localStorage.getItem("UserId") != null &&
          window.localStorage.getItem("UserId") != undefined
        ) {
          this.ms
            .RemoveDiscountCard(
              window.localStorage.getItem("UserId"),
              this.MemberId,
              this.Card_DrugName,
              this.Card_Quantity,
              this.Card_Type,
              this.Card_Dosage,
              this.PharmacyPrice,
              this.PharmacyName,
              this.PAddress1,
              this.PAddress3,
              this.PharmacyPhone,
              this.DrugMultiSource,
              this.PharmacyType
            )
            // .pipe(map((res) => res.json()))
            .subscribe((data) => {
              if (data.length > 0) {
                if ((data[0].Status = "Success")) {
                  this.IsSave = "Save";
                  $(".button_sec > div:first-child")
                    .addClass("save")
                    .removeClass("saved");
                  this.RemoveDiscountCard_login(
                    this.SelectedCardId,
                    this.Card_PharmacyId
                  );
                }
              }
            });
        } else {
          try {
            let Original_CardList = JSON.parse(
              window.localStorage.getItem("CardListLocally")
            );
            let Filter_CardList = Original_CardList.filter((item) => {
              return (
                item["PharmacyId"] !=
                window.localStorage.getItem("SelectedPhamacyId")
              );
            });
            window.localStorage.setItem(
              "CardListLocally",
              JSON.stringify(Filter_CardList)
            );
            this.IsSave = "Save";
            //this.navCtrl.setRoot(MyRxPage);
          } catch { }
        }
      }

    }
  }
  removeforgot(event) {
    this.ManualQuantity_box = false;
  }
  SaveUserSearchCards(
    UserId: string,
    UserCharId: string,
    PharmacyName: string,
    PharmacyType: string,
    DrugName: string,
    Price: string,
    Quantity: string,
    Dose: string,
    Form: string,
    PharmacyPhone: string,
    Address1: string,
    Address2: string,
    gpid: string,
    multisource: string
  ) {
    this.ms
      .SaveUserCards(
        UserId,
        UserCharId,
        PharmacyName,
        PharmacyType,
        DrugName,
        Price,
        Quantity,
        Dose,
        Form,
        PharmacyPhone,
        Address1,
        Address2,
        gpid,
        multisource,
        this.RXBin,
        this.RXCPN,
        this.DialNumber,
        this.Plogo
      )
      // .pipe(map((res) => res.json()))
      .subscribe((data) => {
        if (data.length > 0) {
          if (data[0].Status == "Success") {
            this.SelectedCardId = data[0].CardId;
            this.IsSave = "Remove";
            $(".button_sec > div:first-child")
              .addClass("saved")
              .removeClass("save");
            try {
              if (
                window.localStorage.getItem("CardListLocally_Login") != null ||
                window.localStorage.getItem("CardListLocally_Login") !=
                undefined
              ) {
                let CardListData = JSON.parse(
                  window.localStorage.getItem("CardListLocally_Login")
                );
                this.SavedCardList_Login = [];
                for (let x3 of CardListData) {
                  if (x3.CardId == undefined) {
                    x3.CardId = "-1";
                  }
                  let TempItem = {
                    UserId: x3.UserId,
                    PharmacyType: x3.PharmacyType,
                    UserGroup: window.localStorage.getItem('UserGroup'),
                    PharmacyPhone: x3.PharmacyPhone,
                    UserCharId: x3.UserCharId,
                    PharmacyName: x3.PharmacyName,
                    Address1: x3.Address1,
                    Address2: x3.Address2,
                    DrugName: x3.DrugName,
                    Price: x3.Price,
                    Quantity: x3.Quantity,
                    Dose: x3.Dose,
                    Form: x3.Form,
                    GPId: x3.GPId,
                    MultiSource: x3.MultiSource,
                    Distance: x3.Distance,
                    CardId: x3.CardId,
                    PharmacyId: x3.PharmacyId,
                    UID: x3.UID,
                  };
                  this.SavedCardList_Login.push(TempItem);
                }
              }
              let newItem = {
                UserId: UserId,
                PharmacyType: PharmacyType,
                UserGroup: "",
                PharmacyPhone: PharmacyPhone,
                UserCharId: UserCharId,
                PharmacyName: PharmacyName,
                Address1: Address1,
                Address2: Address2,
                DrugName: DrugName,
                Price: Price,
                Quantity: Quantity,
                Dose: Dose,
                Form: Form,
                GPId: "",
                MultiSource: multisource,
                Distance: "",
                CardId: this.SelectedCardId,
                PharmacyId: this.Card_PharmacyId,
                UID: this.Card_UID,
              };
              this.SavedCardList_Login.push(newItem);
              window.localStorage.setItem(
                "CardListLocally_Login",
                JSON.stringify(this.SavedCardList_Login)
              );
            } catch { }
          } else {
            this.IsSave = "Save";
            $(".button_sec > div:first-child")
              .addClass("save")
              .removeClass("saved");
          }
        }
      });
  }
  PharmacyHelpLine() {
    this.PharmacistHelpline = this.HelpLine;
  }
  PrintMe() {
    this.PharmacistHelpline = this.HelpLine;
    setTimeout(() => {
      window.print();
    }, 600);
  }
  ClearMessage(event: any) {
    if (event.target.name == "ShareEmail") {
      this.SendDiscountMessage = "";
      $(".email_field").removeClass("error_msg");
    }
    if ($(".sendbtn").hasClass("success")) {
      $(".btnsnd").removeClass("success").text("Get Discount!");
    }
    if (this.checkLoginState()) {

    }
    else {
      $('.CheckLoginState')[0].click();
    }
  }
  clearClassMsg() {
    $(".email_field").removeClass("error_msg");
    this.SendDiscountMessage = "";
  }
  ShareDiscountCard(event) {
    this.GroupNumber = window.localStorage.getItem('UserGroup');
    setTimeout(() => {
      let cardid = event.target.previousElementSibling.previousElementSibling.id;
      $(".email_field").removeClass("error_msg");
      let email_REGEX = /[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
      if (this.ms.IsNullOrEmptyField(this.UserInputEmail) == true) {
        this.SendDiscountMessage = "Email is required";
        $(".email_field").addClass("error_msg");
        return false;
      } else if (email_REGEX.test(this.UserInputEmail) == false) {
        $(".email_field").addClass("error_msg");
        this.SendDiscountMessage = "Please enter correct email or valid email";
        return false;
      } else {
        if (this.checkLoginState()) {
          $(".loadProcess").addClass("loader");
          // $(".loadProcess").css({ display: "block" });
          let ShareCard_Params =
            this.Card_DrugName +
            "|" +
            this.Card_Type +
            "|" +
            this.Card_Dosage +
            "|" +
            this.Card_Quantity +
            "|" +
            this.PharmacyPrice +
            "|" +
            this.PharmacyName +
            "|" +
            this.PAddress1 +
            "|" +
            this.PAddress3 +
            "|" +
            this.UserInputEmail;
          this.googleAnalyticsService.eventEmitter(
            "Pharmacy Page",
            "Share Discount Card",
            ShareCard_Params,
            0
          );
          // group number condition
          if (window.localStorage.getItem('UserGroup') === 'GST1016') {
            this.MemberId = '48786';
          }
          let wholesalername = window.localStorage.getItem('WhName')
          if (wholesalername == "Avenel" && window.localStorage.getItem('UserGroup') != 'GST1016') {
            this.MemberId = "12345"
          }
          else {
            this.MemberId = '100-4542';
          }
          // let wholesalername = window.localStorage.getItem('WhName')
          // if (wholesalername == "Avenel") {
          //   this.MemberId = "12345"
          // }
          if (this.MemberId == null || this.MemberId == undefined) {
            // this.MemberId = this.rs.randomMemberId();
            if (
              window.localStorage.getItem("UserCharId") == null ||
              window.localStorage.getItem("UserCharId") == undefined
            ) {
              window.localStorage.setItem("UserCharId", this.MemberId);
            }
          }
          this.ms
            .SaveUserShareCard(
              window.localStorage.getItem("UserId"),
              this.MemberId,
              this.Card_DrugName,
              this.Card_Quantity,
              this.Card_DrugForm,
              this.Card_Dosage,
              this.PharmacyPrice,
              this.PharmacyName,
              this.PAddress1,
              this.PAddress3,
              this.PharmacyPhone,
              this.RXBin,
              this.RXCPN,
              this.GroupNumber,
              this.UserInputEmail,
              this.PharmacistHL,
              this._Lat + "," + this._Long
            )
            // .pipe(map((res) => res.json()))
            .subscribe((data) => {
              if (data.length > 0) {
                if (data[0].Status == "Success") {
                  $(".loadProcess").css({ display: "none" });
                  $(".loadProcess").removeClass("loader");
                  this.UserInputEmail = null;
                  this.SendDiscountMessage = "";
                  this.UserInputEmail = "";
                  $(".sendbtn.btnsnd").addClass("success").text("Check Your Email! ");
                } else {
                  this.SendDiscountMessage = data[0].Status;
                }
              } else {
                this.SendDiscountMessage = "Please try after sometime.";
              }
            });

          // var node = event.target.previousElementSibling;
          // node.style.opacity = '1';
          // var img;
          // var filename;
          // var newImage;
          // var dataurl;


          // domtoimage.toPng(node, { bgcolor: '#fff' })

          //   .then(function (dataUrl) {
          //     console.log(dataUrl)
          //     node.style.opacity = '0';
          //     dataurl = dataUrl;
          //     img = new Image();
          //     img.src = dataUrl.split(',')[1];
          //     newImage = img.src;
          //     img.onload = function () {
          //       var doc;

          //       var pdf = new jspdf('l', 'pt', [event.target.previousElementSibling.previousElementSibling.offsetWidth, event.target.previousElementSibling.previousElementSibling.offsetHeight]);
          //       pdf.addImage(dataUrl, 'PNG', 0, 0, event.target.previousElementSibling.previousElementSibling.offsetWidth, event.target.previousElementSibling.previousElementSibling.offsetHeight);
          //       //pdf.save(name+'.pdf');


          //     };
          //   })
          //   .catch(function (error) {
          //     // Error Handling

          //   });
        }
      }
      // setTimeout(() => {
      //   this.sendattached(dataurl.replace('data:image/png;base64,', ''))
      // }, 1000);
    }, 300);
  }

  sendattached(dataUrl) {

    this.ApiService.addToMailingListt(
      this.UserInputEmail,
      window.localStorage.getItem('FirstName'),
      '',
      window.localStorage.getItem('UserGroup'),
      '',
      dataUrl,
      this.MemberId
    )
      // .pipe(map((res) => res.json()))

      .subscribe((data) => {
        $(".loadProcess").css({ display: "none" });
        $(".loadProcess").removeClass("loader");
        this.UserInputEmail = null;
        this.SendDiscountMessage = "";
        this.UserInputEmail = "";
        $(".btnsnd").addClass("success").text("Check Your Email! ");

      });
  }

  DiscountCardSavedStatus(
    UId: string,
    CardId: string,
    Drugname: string,
    Price: string,
    Form: string,
    Dose: string
  ) {
    try {
      this.DoseDescriptor_JSON = JSON.parse(this.rs.DrugDoseDescription());
      let _DoseFilter = this.DoseDescriptor_JSON.filter((item) => {
        return item["Dose"].toLowerCase() == Form.toLowerCase();
      });
      if (_DoseFilter.length > 0) {
        Form = _DoseFilter[0]["Descrption"];
      }
      let LocalcardCardList = [];
      if (
        this.rs.IsLoginRequired() == "Yes" &&
        window.localStorage.getItem("SkipSignup") == "signupskipped"
      ) {
        try {
          LocalcardCardList = JSON.parse(
            window.localStorage.getItem("CardListLocally")
          );
          let _CardFilter = LocalcardCardList.filter((item) => {
            return (
              item["PharmacyId"].toLowerCase() == UId.toLowerCase() &&
              item["DrugName"].trim().toLowerCase() ==
              Drugname.trim().toLowerCase() &&
              item["Price"] == Price &&
              item["Form"].trim().toLowerCase() == Form.trim().toLowerCase() &&
              item["Dose"].trim().toLowerCase() == Dose.trim().toLowerCase()
            );
          });
          if (_CardFilter.length > 0) {
            this.IsSave = "Remove";
          } else {
            this.IsSave = "Save";
          }
        } catch {
          return "Save";
        }
      } else {
        try {
          // LocalcardCardList = JSON.parse(window.localStorage.getItem('CardListLocally'));
          LocalcardCardList = JSON.parse(
            window.localStorage.getItem("CardListLocally")
          );
          let _CardFilter = LocalcardCardList.filter((item) => {
            return (
              item["PharmacyId"].toLowerCase() == UId.toLowerCase() &&
              item["DrugName"].trim().toLowerCase() ==
              Drugname.trim().toLowerCase() &&
              item["Price"] == Price &&
              item["Form"].trim().toLowerCase() == Form.trim().toLowerCase() &&
              item["Dose"].trim().toLowerCase() == Dose.trim().toLowerCase()
            );
          });
          if (_CardFilter.length > 0) {
            return "Remove";
          } else {
            return "Save";
          }
        } catch {
          return "Save";
        }
      }
    } catch {
      return "Save";
    }
  }
  DiscountCardSavedStatus_login(
    UserId: string,
    UserCharId: string,
    PharmacyName: string,
    PharmacyType: string,
    DrugName: string,
    Price: string,
    Quantity: string,
    Dose: string,
    Form: string,
    PharmacyPhone: string,
    Address1: string,
    Address2: string,
    multisource: string
  ) {
    let Card_Status = "Save";
    let LocalcardCardList = [];
    if (Price.toString().indexOf("$") > -1) {
    } else {
      Price = "$" + Price;
    }
    try {
      this.ms
        .GetUserCards(UserId)
        // .pipe(map((res) => res.json()))
        .subscribe((data) => {
          let serverdata = JSON.stringify(data);
          window.localStorage.setItem("CardListLocally_Login", serverdata);
          try {
            LocalcardCardList = JSON.parse(
              window.localStorage.getItem("CardListLocally_Login")
            );
            let _CardFilter = LocalcardCardList.filter((item) => {
              return (
                //item["UserId"] == UserId
                item["PharmacyType"].toLowerCase() ==
                PharmacyType.toLowerCase() &&
                item["PharmacyPhone"].toLowerCase() ==
                PharmacyPhone.toLowerCase() &&
                item["UserCharId"].toLowerCase() == UserCharId.toLowerCase() &&
                item["PharmacyName"].toLowerCase() ==
                PharmacyName.toLowerCase() &&
                item["Address1"].trim().toLowerCase() ==
                Address1.trim().toLowerCase() &&
                item["Address2"].trim().toLowerCase() ==
                Address2.trim().toLowerCase() &&
                item["DrugName"].toLowerCase() == DrugName.toLowerCase() &&
                item["Price"] == "$" + this.convertstringtoint(Price) &&
                item["Quantity"] == parseInt(Quantity) &&
                item["Dose"].toLowerCase() == Dose.toLowerCase() &&
                item["Form"].toLowerCase() == Form.toLowerCase() &&
                item["MultiSource"].toLowerCase() == multisource.toLowerCase()
              );
            });
            if (_CardFilter.length > 0) {
              Card_Status = "Remove";
              this.IsSave = Card_Status;
              this.showcard = true;
            } else {
              Card_Status = "Save";
              this.IsSave = Card_Status;
              this.showcard = true;
            }
          } catch {
            Card_Status = "Save";
            this.IsSave = Card_Status;
            this.showcard = true;
          }
        });
    } catch {
      Card_Status = "Save";
      this.IsSave = Card_Status;
      this.showcard = true;
    }
  }
  RemoveDiscountCard_login(CardId: string, UId: string) {
    try {
      let Original_CardList_Login = JSON.parse(
        window.localStorage.getItem("CardListLocally_Login")
      );
      let Filter_CardList_Login = Original_CardList_Login.filter((item) => {
        return item["UID"] != UId;
      });
      window.localStorage.setItem(
        "CardListLocally_Login",
        JSON.stringify(Filter_CardList_Login)
      );
    } catch { }
  }
  get isSidebarVisible(): boolean {
    return this.sidebarService.isSidebarVisible;
  }
  filteron_mobile() {
    $(".select_filterList_parent").addClass("Show_filter_on_mobile");
  }
  hide_filter_mobile_on() {
    $(".select_filterList_parent").removeClass("Show_filter_on_mobile");
  }
  GetLatLongByCo_ordinate(lat: string, long: string) {
    if (lat == "" || lat == undefined || lat == null) {
      return false;
    }
    this.ms
      .GetLocationByLatLongWithSensor(lat, long)
      // .pipe(map((res) => res.json()))
      .subscribe((data) => {
        try {
          for (let ix of data.results) {
            if (ix["types"][0] == "street_address") {
              let tempaddress = "";
              for (
                let ad = 1;
                ad < ix["formatted_address"].split(",").length;
                ad++
              ) {
                if (
                  tempaddress == "" ||
                  tempaddress == null ||
                  tempaddress == undefined
                ) {
                  tempaddress = ix["formatted_address"].split(",")[ad];
                } else {
                  tempaddress =
                    tempaddress + "," + ix["formatted_address"].split(",")[ad];
                }
              }
              this._CurrLocation = tempaddress; //ix["formatted_address"];
              window.localStorage.setItem(
                "UserCurrentAddress",
                this._CurrLocation
              );
              break;
            }
          }
          try {
            if (data.results.length > 0) {
              for (let ix of data.results[0]["address_components"]) {
                if (ix["types"] == "postal_code") {
                  // window.localStorage.setItem("userZipCode", ix["long_name"]);
                  this._ZipCode = ix["long_name"];
                  this.IsAutoLocation = "Found";
                  break;
                }
              }
            }
          } catch { }
        } catch { }
      });
    // if(window.localStorage.getItem("userZipCode")!=""&&window.localStorage.getItem("userZipCode")!=null&&window.localStorage.getItem("userZipCode")!=undefined)
    // {
    //   this.router.navigate(['/Pharmacy']);
    // }
  }
  GetAutoLocation(e) {
    var AutoLocate = e.target.checked;
    if (AutoLocate == true) {
      try {
        this.ZipCodeMessage = "";
        $(".PharmacyNear").removeClass("error_msg");
        this.googleAnalyticsService.eventEmitter(
          "Click for Auto Location",
          "User Current Co-Ordinate",
          window.localStorage.getItem("_LocalLat") +
          "," +
          window.localStorage.getItem("_LocalLang"),
          0
        );
        window.localStorage.setItem(
          "UserLat",
          window.localStorage.getItem("_LocalLat")
        );
        window.localStorage.setItem(
          "UserLong",
          window.localStorage.getItem("_LocalLang")
        );
        this.GetLatLongByCo_ordinate(
          window.localStorage.getItem("_LocalLat"),
          window.localStorage.getItem("_LocalLang")
        );
      } catch { }
    } else {
      this._UserZip = "";
      this.IsAutoLocation = "Not Found";
      this._CurrLocation = "";
      window.localStorage.setItem("UserLat", "");
      window.localStorage.setItem("UserLong", "");
      window.localStorage.setItem("userZipCode", "");
    }
  }
  printcard(event) {
    window.open('/printmycard?source=web&groupno=' + window.localStorage.getItem('UserGroup') + '&med=' + window.localStorage.getItem('SelectedDrugName'));
  }
}
